import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageSkeleton, Tab, Table, TabPanel, Tabs } from '../../components';
import useApi from '../../hooks/useApi';
import { useSnackbar } from '../../providers/snackbar';
import {
  deleteClient as deleteClientService,
  getAllClients as getClients,
  getClientById as getClient,
  patchEditClient,
  postCreateClient,
} from '../../services/clients';
import { getCompanyById } from '../../services/company';
import { getAllDashs } from '../../services/dashs';
import {
  deleteRemovePermission,
  getPermission,
  postCreatePermission,
} from '../../services/permissions';
import {
  deleteUser,
  getAllUser,
  getUserById,
  patchEditUser,
  postCreateUser,
} from '../../services/user';

const CompaniesDetails = () => {
  const [loading, setLoading] = useState(false);
  const [defaultEditValue, setDefaultEditValue] = useState({});
  const [clientsData, setClientsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [company, setCompany] = useState({});
  const handleChangeTab = (_event, tab) => setTabValue(tab);
  const { companyId } = useParams();
  const callAPI = useApi();

  const { showSnackbar } = useSnackbar();

  const getCompany = useCallback(async () => {
    const response = await callAPI(getCompanyById, companyId);
    if (response) {
      setCompany(response);
    }
  }, [callAPI, companyId]);

  const getAllClients = useCallback(
    async (searchValue, companyNumberId = companyId, paginationParams) => {
      const { skip = 0, take = 5 } = paginationParams || {};
      setLoading(true);
      const response = await callAPI(getClients, {
        companyId: companyNumberId,
        searchValue,
        skip,
        take,
      });
      if (response) {
        setClientsData(response);
      }
      setLoading(false);
    },
    [callAPI, companyId],
  );

  const createClient = useCallback(
    async bodyParams => {
      setLoading(true);
      const response = await callAPI(postCreateClient, bodyParams);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'O cliente foi cadastrado com sucesso!',
        });
        getAllClients();
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'O cliente não foi cadastrado, verifique os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllClients, showSnackbar],
  );

  const getClientById = useCallback(
    async clientId => {
      const response = await callAPI(getClient, clientId);
      if (response) {
        setDefaultEditValue(response);
      }
    },
    [callAPI],
  );

  const editClientName = useCallback(
    async (bodyParams, clientId) => {
      setLoading(true);
      const response = await callAPI(patchEditClient, { bodyParams, clientId });

      if (response) {
        getClientById(clientId);
        getAllClients();
        showSnackbar({
          type: 'success',
          message: 'O cliente foi editado com sucesso!',
        });
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'O cliente não foi editado, verifique os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllClients, getClientById, showSnackbar],
  );

  const deleteClient = useCallback(
    async clientId => {
      setLoading(true);
      const response = await callAPI(deleteClientService, clientId);

      if (response) {
        getAllClients();
        showSnackbar({
          type: 'success',
          message: 'O cliente foi deletado com sucesso!',
        });
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'O cliente não foi deletado, verifique os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllClients, showSnackbar],
  );

  const getAllUsers = useCallback(
    async (searchValue, paginationParams) => {
      const { skip = 0, take = 5 } = paginationParams || {};
      setLoading(true);
      const response = await callAPI(getAllUser, {
        companyId,
        searchValue,
        skip,
        take,
      });
      if (response) {
        setUsersData(response);
      }
      setLoading(false);
    },
    [callAPI, companyId],
  );

  const editUser = useCallback(
    async params => {
      setLoading(true);
      const response = await callAPI(patchEditUser, params);

      if (response) {
        getAllUsers();
        showSnackbar({
          type: 'success',
          message: 'O usuário foi editado com sucesso!',
        });
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'O usuário não foi editado, verifique os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllUsers, showSnackbar],
  );
  const removeUser = useCallback(
    async id => {
      setLoading(true);
      const response = await callAPI(deleteUser, id);

      if (response) {
        getAllUsers();
        showSnackbar({
          type: 'success',
          message: 'O usuário foi deletado com sucesso!',
        });
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'O usuário não foi deletado, verifique os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllUsers, showSnackbar],
  );

  const createUser = useCallback(
    async bodyParams => {
      setLoading(true);
      const response = await callAPI(postCreateUser, {
        ...bodyParams,
        companyId,
      });

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'O usuário foi cadastrado com sucesso',
        });
        getAllUsers();
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'O usuário não foi cadastrado, verifique os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, companyId, getAllUsers, showSnackbar],
  );

  const getDashsByClient = useCallback(
    async (clientId, skip, take) => {
      const response = await callAPI(getAllDashs, { clientId, skip, take });
      if (response) {
        return response;
      }
      return null;
    },
    [callAPI],
  );

  const createPermission = useCallback(
    async ({ userId, permission }) => {
      setLoading(true);
      const response = await callAPI(postCreatePermission, {
        userId,
        permission,
      });
      setLoading(false);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'A permissão foi cadastrada com sucesso!',
        });
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'A permissão não foi cadastrada, verifique os dados!',
      });
      return false;
    },
    [callAPI, showSnackbar],
  );

  const getAllPermissions = useCallback(
    async userId => {
      const response = await callAPI(getPermission, userId);
      if (response) {
        setPermissionsData(response);
      }
    },
    [callAPI],
  );

  const removePermission = useCallback(
    async permissionId => {
      setLoading(true);
      const response = await callAPI(deleteRemovePermission, permissionId);
      setLoading(false);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'A permissão foi deletada com sucesso!',
        });
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'A permissão não foi deletada, verifique os dados!',
      });
      return false;
    },
    [callAPI, showSnackbar],
  );

  useEffect(() => {
    getAllClients(null, companyId, {});
    getCompany();
  }, [companyId, getAllClients, getCompany]);

  useEffect(() => {
    getAllUsers(null, {});
  }, [getAllUsers, companyId]);

  const mapTabPanel = {
    0: (
      <Table
        tableType="client"
        companyId={companyId}
        data={clientsData}
        getAll={getAllClients}
        create={createClient}
        edit={editClientName}
        remove={deleteClient}
        defaultEditValue={defaultEditValue}
        getById={getClientById}
        companyName={company.name}
        loading={loading}
      />
    ),
    1: (
      <Table
        tableType="user"
        data={usersData}
        companyId={companyId}
        getAll={getAllUsers}
        create={createUser}
        edit={editUser}
        remove={removeUser}
        getById={getUserById}
        getDashsByClient={getDashsByClient}
        createPermission={createPermission}
        clients={clientsData}
        companyName={company.name}
        getAllPermissions={getAllPermissions}
        removePermission={removePermission}
        permissionsData={permissionsData}
        loading={loading}
      />
    ),
  };

  return (
    <>
      <PageSkeleton company={company} details>
        <Tabs onChange={handleChangeTab} value={tabValue}>
          <Tab label="Clientes" index={0} />
          <Tab label="Usuários" index={1} />
        </Tabs>

        <TabPanel>{mapTabPanel[tabValue]}</TabPanel>
      </PageSkeleton>
    </>
  );
};

export default CompaniesDetails;
