import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Cache } from 'react-avatar';
import * as S from './styled';

const CONTROL_READY_STATE = 2;
const acceptTypes = 'image/png, image/gif, image/jpeg';

const Avatar = ({
  avatarPath,
  setFile,
  change,
  name,
  round,
  size,
  onChange,
}) => {
  const [avatarSourcePath, setAvatarSourcePath] = useState(avatarPath || '');
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (avatarPath) {
      setAvatarSourcePath(avatarPath);
    }
  }, [avatarPath]);

  const getLocalUpload = async uploadEvent => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === CONTROL_READY_STATE) {
        setAvatarSourcePath(reader.result);
      }
    };

    const avatarFile = uploadEvent.target.files[0];

    onChange(avatarFile);

    setFile(avatarFile);
    reader.readAsDataURL(uploadEvent.target.files[0]);
  };

  const onButtonClick = () => {
    inputFileRef.current.click();
  };

  const cache = new Cache({
    sourceTTL: 0,
  });

  const avatarProps =
    !name && !avatarSourcePath
      ? {
          name: 'LOGO',
          src: '',
          maxInitials: 4,
          initials: avatarName => avatarName,
        }
      : {
          name,
          src: avatarSourcePath,
          maxInitials: 2,
        };

  const returnAvatar = change ? (
    <>
      <S.Wrapper size={size}>
        <S.CustomAvatar
          size={size}
          onClick={onButtonClick}
          round={round}
          style={{ cursor: 'pointer' }}
          cache={cache}
          change={change}
          {...avatarProps}
        />
      </S.Wrapper>
      <S.Upload
        type="file"
        id="file"
        ref={inputFileRef}
        accept={acceptTypes}
        onChange={getLocalUpload}
      />
    </>
  ) : (
    <S.CustomAvatar
      name={name}
      src={avatarSourcePath}
      size={size}
      round={round}
      maxInitials={2}
      cache={cache}
    />
  );

  return returnAvatar;
};

Avatar.defaultProps = {
  avatarPath: null,
  setFile: () => null,
  change: false,
  name: null,
  round: '.5rem',
  size: '60',
};
Avatar.propTypes = {
  avatarPath: PropTypes.node,
  setFile: PropTypes.func,
  change: PropTypes.bool,
  name: PropTypes.string,
  round: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.string,
};

export default Avatar;
