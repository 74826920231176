import { Button as CustomButton, lighten } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Button = styled(CustomButton)`
  ${({ theme, width, color }) => css`
    background-color: ${theme.colors.primary};
    min-height: 4.5rem;
    ${width && `width: ${width}px;`}

    &.MuiButtonBase-root {
      margin-top: 2.5rem;
      text-transform: none;
      font-size: 1.3rem;
    }

    &.MuiButtonBase-root.Mui-disabled {
      background-color: ${lighten(
        color === 'secondary' ? theme.colors.red : theme.colors.primary,
        0.3,
      )};
    }
  `}
`;
