import PropTypes from 'prop-types';
import ClientTable from './ClientTable';
import CompanyTable from './CompanyTable';
import DashTable from './DashTable';
import UserTable from './UserTable';

const Table = ({
  tableType,
  data,
  create,
  edit,
  permissions,
  remove,
  ...props
}) => {
  const tableTypeMap = {
    user: (
      <UserTable
        data={data}
        create={create}
        edit={edit}
        permissions={permissions}
        remove={remove}
        {...props}
      />
    ),
    dash: (
      <DashTable
        data={data}
        create={create}
        edit={edit}
        permissions={permissions}
        remove={remove}
        {...props}
      />
    ),
    company: (
      <CompanyTable
        data={data}
        create={create}
        edit={edit}
        permissions={permissions}
        remove={remove}
        {...props}
      />
    ),
    client: (
      <ClientTable
        data={data}
        create={create}
        edit={edit}
        permissions={permissions}
        remove={remove}
        {...props}
      />
    ),
  };
  return tableTypeMap[tableType];
};

Table.defaultProps = {
  tableType: 'user',
  data: [],
  create: () => null,
  edit: () => null,
  permissions: () => null,
  remove: () => null,
};

Table.propTypes = {
  tableType: PropTypes.oneOf(['user', 'dash', 'client', 'company', 'client']),
  data: PropTypes.array,
};

export default Table;
