import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const Head = ({ title, children }) => (
  <Helmet>
    <title>{title}</title>
    {children}
  </Helmet>
);

Head.defaultProps = {
  title: `We.dash`,
  children: null,
};

Head.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Head;
