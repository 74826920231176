import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import USERS_ICON from '../../assets/icon/groupIcon.svg';
import HOTEL_ICON from '../../assets/icon/hotelIcon.svg';
import PIE_ICON from '../../assets/icon/pieIcon.svg';
import WHITE_LOGO from '../../assets/img/weDashWhite.svg';
import { Head, PageSkeleton } from '../../components';
import useApi from '../../hooks/useApi';
import { getAllCompany } from '../../services/company';
import { getDashLength } from '../../services/dashs';
import { getUserLength } from '../../services/users';
import * as S from './styled';

const Home = () => {
  const callAPI = useApi();
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfDashs, setNumberOfDashs] = useState(0);
  const [numberOfCompanies, setNumberOfCompanies] = useState(0);

  const responseAllUsers = useCallback(async () => {
    const response = await callAPI(getUserLength);
    if (response) {
      setNumberOfUsers(response.total);
    }
  }, [callAPI]);

  const responseAllDashs = useCallback(async () => {
    const response = await callAPI(getDashLength);
    if (response) {
      setNumberOfDashs(response);
    }
  }, [callAPI]);

  const responseAllCompanies = useCallback(async () => {
    const response = await callAPI(getAllCompany);
    if (response) {
      setNumberOfCompanies(response.total);
    }
  }, [callAPI]);

  useEffect(() => {
    responseAllUsers();
    responseAllDashs();
    responseAllCompanies();
  }, [responseAllDashs, responseAllUsers, responseAllCompanies]);

  const cardData = [
    {
      icon: USERS_ICON,
      title: 'Nº total de companhias',
      value: numberOfCompanies,
    },
    {
      icon: HOTEL_ICON,
      title: 'Nº total de Usuários',
      value: numberOfUsers,
    },
    {
      icon: PIE_ICON,
      title: 'Nº total de Dashboards',
      value: numberOfDashs,
    },
  ];

  return (
    <>
      <Head title="We.Dash | Dashboard" />
      <S.Wrapper>
        <PageSkeleton
          logoSrc={WHITE_LOGO}
          logoAlt="Escrita we.dash em cor clara"
          title="Início"
        >
          <S.Box display="flex">
            {cardData.map(({ icon, title, value }, index) => (
              <S.Card key={index}>
                <S.CardHeader>
                  <S.Img src={icon} alt="usuário" />
                  <Typography
                    variant="h5"
                    style={{ fontSize: '1.2rem', fontWeight: 500 }}
                  >
                    {title}
                  </Typography>
                </S.CardHeader>
                <S.CardBody>
                  <Typography variant="h1" style={{ fontSize: '9rem' }}>
                    {value}
                  </Typography>
                </S.CardBody>
              </S.Card>
            ))}
          </S.Box>
        </PageSkeleton>
      </S.Wrapper>
    </>
  );
};

export default Home;
