import { Box as MaterialBox } from '@material-ui/core';
import styled from 'styled-components';
import BLUE from '../../assets/img/blueLineImg.svg';
import GREEN from '../../assets/img/greenLineImg.svg';
import LOGO from '../../assets/img/logo.png';
import { defaultTheme } from '../../styles/defaultTheme';

export const Wrapper = styled.section`
  position: relative;
  height: 100vh;
  width: 100%;

  :after {
    content: '';
    background-image: url(${BLUE});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    bottom: 0;
    width: 100%;
    min-height: 650px;
    z-index: -1;
  }
  :before {
    content: '';
    background-image: url(${GREEN});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    bottom: 0;
    width: 99vw;
    height: 650px;
    z-index: -1;
  }
`;

export const Box = styled(MaterialBox)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 8%;

  :before {
    content: '';
    position: absolute;
    background-image: url(${LOGO});
    background-repeat: no-repeat;
    background-size: cover;
    width: 150px;
    height: 55px;
    top: 10%;
    left: 5%;
    z-index: -1;
  }
`;

export const Form = styled.form`
  > h3,
  h6 {
    font-weight: 600;
  }
  > h3 {
    margin-bottom: 10px;
  }

  > h6 {
    margin-bottom: 3rem;
  }

  > div {
    margin: 3rem 0 0 0;
  }

  > div label {
    font-weight: 700;
    font-size: 1.1rem;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-weight: 500;
      margin-top: 0.2rem;
    }
    h6:nth-child(2) {
      color: ${defaultTheme.colors.primary};
    }
  }
`;
