import Avatar from 'react-avatar';
import styled, { css } from 'styled-components';
import EditIcon from '../../assets/icon/pencilIconWhite.svg';

export const Wrapper = styled.div`
  ${({ size }) => css`
    position: relative;
    cursor: default;
    user-select: none;
    width: ${size}px;
    height: ${size}px;
  `}
`;

export const Upload = styled.input`
  display: none;
`;

export const CustomAvatar = styled(Avatar)`
  object-fit: contain;
  ${({ round, change }) =>
    change &&
    css`
      &:hover {
        &:after {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: ${typeof round === 'string' ? round : '50%'};
          top: 0;
          left: 0;
          content: url(${EditIcon});
          background: rgba(0, 0, 0, 0.5);
        }
      }
    `}
`;
