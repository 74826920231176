import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import setObjectToFormData from '../../../../helpers/setObjectToFormData';
import EditClient from '../../../../validations/EditClient';
import Avatar from '../../../Avatar';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const EditClientModal = ({
  opened,
  onClose,
  edit,
  getById,
  defaultEditValue,
  variableId,
  companyName,
  closeOnSubmit,
  loading,
}) => {
  const formData = useMemo(() => new FormData(), []);
  const {
    font: { sizes },
    typography,
  } = useTheme();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditClient),
  });

  const onSubmit = useCallback(
    async data => {
      setObjectToFormData(formData, data);
      formData.delete('companyId');
      const response = await edit(formData, variableId);
      if (!response) return;
      reset();
      closeOnSubmit();
    },
    [closeOnSubmit, edit, formData, reset, variableId],
  );

  const onChangeAvatar = file => {
    formData.set('avatar', file);
  };

  useEffect(() => {
    reset({ name: defaultEditValue.name, companyId: companyName });
  }, [companyName, defaultEditValue, reset]);

  useEffect(() => {
    getById(variableId);
  }, [getById, variableId]);

  return (
    <ModalSkeleton
      title="Editar cliente"
      opened={opened}
      onCancel={onClose}
      buttonText="Salvar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <S.AvatarWrapper>
          <Avatar
            name={defaultEditValue.name}
            avatarPath={defaultEditValue.avatarUrl}
            onChange={onChangeAvatar}
            change
            size="80"
            round=".6rem"
          />
          <Typography style={{ fontSize: sizes.large, color: typography.gray }}>
            Logo cliente
          </Typography>
        </S.AvatarWrapper>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="Nome do cliente"
              label="Nome do cliente*"
              InputLabelProps={{
                shrink: true,
              }}
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.name?.message} />
        <Controller
          name="companyId"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              label="Companhia"
              disabled
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.companyId?.message} />
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

EditClientModal.defaultProps = {
  opened: false,
  onClose: () => null,
  edit: () => null,
  defaultEditValue: () => null,
  getById: () => null,
  closeOnSubmit: () => null,
  variableId: '',
  companyName: '',
  loading: false,
};

EditClientModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.func,
  defaultEditValue: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  getById: PropTypes.func,
  variableId: PropTypes.string,
  companyName: PropTypes.string,
  loading: PropTypes.bool,
};

export default EditClientModal;
