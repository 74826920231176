import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const DeleteClientModal = ({
  opened,
  onClose,
  remove,
  variableId,
  closeOnSubmit,
  loading,
}) => {
  const deleteClient = async () => {
    const response = await remove(variableId);
    if (!response) return;
    closeOnSubmit();
  };

  return (
    <ModalSkeleton
      title="Excluir cliente"
      opened={opened}
      onCancel={onClose}
      buttonText="Excluir"
      onSend={() => null}
      onDelete={deleteClient}
      loading={loading}
      isErrorButton
    >
      <S.ModalWrapper>
        <Typography
          variant="h5"
          style={{ fontSize: '1.8rem', fontWeight: 500 }}
        >
          Realmente deseja excluir?
        </Typography>
        <S.Typography variant="h5">
          Este cliente será removido permanentemente.
        </S.Typography>
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

DeleteClientModal.defaultProps = {
  opened: false,
  onClose: () => null,
  remove: () => null,
  closeOnSubmit: () => null,
  variableId: '',
  loading: false,
};

DeleteClientModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  remove: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  variableId: PropTypes.string,
  loading: PropTypes.bool,
};
export default DeleteClientModal;
