import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from './defaultTheme';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body {
    font-size: 62.5%;
    background-color: ${defaultTheme.colors.lightBlue};
  }
  .MuiTablePagination-menuItem {
    font-size: 1.5rem;
  }
  .MuiMenu-list {
    outline: 0;
    overflow-y: scroll;
    max-height: 250px;
  }
  body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow-x: hidden;
  }
`;

export default GlobalStyles;
