import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styled';

const Spinner = ({ type, shapeColor, loadColor }) => (
  <S.Spinner type={type} shapeColor={shapeColor} loadColor={loadColor} />
);

Spinner.defaultProps = {
  type: 'button',
  shapeColor: 'black',
  loadColor: 'white',
};

Spinner.propTypes = {
  type: PropTypes.string,
  shapeColor: PropTypes.string,
  loadColor: PropTypes.string,
};

export default Spinner;
