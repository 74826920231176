import React from 'react';
import { Link } from 'react-router-dom';
import { Head } from '../../components';
import * as S from './styled';

import RETURN from '../../assets/icon/returnArrowIcon.svg';

const NotFound = () => (
  <>
    <Head title="404: Not Found" />
    <S.Container />
    <Link to="/">
      <S.Button type="button">
        <img
          src={RETURN}
          alt="Seta curvada para esquerda, indicando o símbolo de retorno"
        />
        <p>Return to Home</p>
      </S.Button>
    </Link>
  </>
);

export default NotFound;
