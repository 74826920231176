import PropTypes from 'prop-types';
import * as S from './styled';

const Tooltip = ({ children, bottom, maxWidth, color }) => (
  <S.StyledToolTip bottom={bottom} maxWidth={maxWidth} color={color}>
    {children}
  </S.StyledToolTip>
);

Tooltip.defaultProps = {
  bottom: false,
  maxWidth: '',
  color: 'violet',
};
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  bottom: PropTypes.bool,
  maxWidth: PropTypes.string,
  color: PropTypes.string,
};

export default Tooltip;
