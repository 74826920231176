import { useCallback } from 'react';

export default function useAPI() {
  const callApi = useCallback(async (apiCall, params = {}) => {
    try {
      const { data } = await apiCall(params);
      return data || true;
    } catch (error) {
      return false;
    }
  }, []);

  return callApi;
}
