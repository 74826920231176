import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styled';

const Tabs = ({ children, ...rest }) => (
  <S.Tabs {...rest} indicatorColor="inherit" textColor="inherit">
    {children}
  </S.Tabs>
);

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;
