import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RiArrowLeftLine as ReturnIcon } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import DEFAULT_LOGO from '../../assets/img/weDashWhite.svg';
import Avatar from '../Avatar';
import Header from '../Header';
import SideBar from '../SideBar';
import * as S from './styled';

const PageSkeleton = ({
  logoSrc,
  logoAlt,
  title,
  details,
  children,
  company,
  client,
  dash,
}) => {
  const [avatarData, setAvatarData] = useState({});

  useEffect(() => {
    setAvatarData(client.name ? client : company);
  }, [client, company]);
  const redirect = useHistory();
  const hasDashClient = {
    true: (
      <S.Typography variant="h4" textColor="default">
        {`${company?.name} > `}
        <span>{client?.name}</span>
      </S.Typography>
    ),
    false: (
      <S.Typography variant="h4" textColor="primary">
        Voltar
      </S.Typography>
    ),
  };

  return (
    <S.Container>
      <Header logoSrc={logoSrc} logoAlt={logoAlt} boxShadow={2} />
      <Box display="flex" height="100%">
        <SideBar />
        <Box px={4} py={3} width="100%">
          <S.Content>
            {details ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  py={2}
                >
                  <Box display="flex" alignItems="center">
                    <S.ReturnButton
                      type="button"
                      onClick={() => redirect.goBack()}
                    >
                      <ReturnIcon />
                    </S.ReturnButton>
                    {hasDashClient[dash]}
                  </Box>

                  <Box display="flex" alignItems="center">
                    <S.Typography variant="h4" textColor="default">
                      {avatarData.name}
                    </S.Typography>
                    <Avatar
                      name={avatarData.name}
                      avatarPath={avatarData.avatarUrl}
                      size="50"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Typography variant="h3">{title}</Typography>
            )}
          </S.Content>
          <div>{children}</div>
        </Box>
      </Box>
    </S.Container>
  );
};

PageSkeleton.defaultProps = {
  logoSrc: DEFAULT_LOGO,
  logoAlt: 'Escrita we.dash',
  title: 'Title',
  children: null,
  details: false,
  company: {},
  client: {},
  dash: false,
};

PageSkeleton.propTypes = {
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  details: PropTypes.bool,
  dash: PropTypes.bool,
  company: PropTypes.object,
  client: PropTypes.object,
};

export default PageSkeleton;
