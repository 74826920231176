import { useCallback, useEffect, useState } from 'react';
import { PageSkeleton, Table } from '../../components';
import useApi from '../../hooks/useApi';
import { useSnackbar } from '../../providers/snackbar';
import {
  deleteCompany,
  getAllCompany,
  getCompanyById,
  patchEditCompany,
  postCreateCompany,
} from '../../services/company';
import * as S from './styled';

const Companies = () => {
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [defaultEditValue, setDefaultEditValue] = useState({});
  const callAPI = useApi();

  const { showSnackbar } = useSnackbar();

  const getAllCompanies = useCallback(
    async (searchValue, { skip = 0, take = 5 }) => {
      setLoading(true);
      const response = await callAPI(getAllCompany, {
        searchValue,
        skip,
        take,
      });
      if (response) {
        setCompanyData(response);
      }
      setLoading(false);
    },
    [callAPI],
  );

  const createCompany = useCallback(
    async bodyParams => {
      setLoading(true);
      const response = await callAPI(postCreateCompany, bodyParams);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'Companhia criada com sucesso!',
        });
        getAllCompanies(null, {});
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'Erro ao criar companhia, favor verificar dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllCompanies, showSnackbar],
  );

  const getCompanyDataById = useCallback(
    async companyId => {
      const response = await callAPI(getCompanyById, companyId);
      if (response) {
        setDefaultEditValue(response);
        return;
      }
      setDefaultEditValue('');
    },
    [callAPI],
  );

  const editCompany = useCallback(
    async (bodyParams, companyId) => {
      setLoading(true);
      const response = await callAPI(patchEditCompany, {
        bodyParams,
        companyId,
      });

      if (!response) {
        showSnackbar({
          type: 'error',
          message: 'Erro ao editar companhia, favor verificar dados!',
        });
        setLoading(false);
        return false;
      }

      showSnackbar({
        type: 'success',
        message: 'Companhia editada com sucesso!',
      });
      getCompanyDataById(companyId);
      getAllCompanies(null, {});
      setLoading(false);
      return true;
    },
    [callAPI, getAllCompanies, getCompanyDataById, showSnackbar],
  );

  const removeCompany = useCallback(
    async companyId => {
      setLoading(true);
      const response = await callAPI(deleteCompany, companyId);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'Companhia deletada com sucesso!',
        });
        getAllCompanies(null, {});
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'Erro ao deletar companhia, favor verificar dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllCompanies, showSnackbar],
  );

  useEffect(() => {
    getAllCompanies(null, {});
  }, [getAllCompanies]);

  return (
    <>
      <PageSkeleton title="Companhias">
        <S.Wrapper>
          <Table
            tableType="company"
            data={companyData}
            create={createCompany}
            edit={editCompany}
            getAll={getAllCompanies}
            getCompanyById={getCompanyDataById}
            defaultEditValue={defaultEditValue}
            remove={removeCompany}
            loading={loading}
          />
        </S.Wrapper>
      </PageSkeleton>
    </>
  );
};
export default Companies;
