import * as yup from 'yup';

const LoginSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(1, 'Não possuí caracters minimos'),
});

export default LoginSchema;
