import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const DeleteDashModal = ({
  opened,
  onClose,
  remove,
  variableId,
  closeOnSubmit,
  loading,
}) => {
  const onRemove = useCallback(async () => {
    const response = await remove(variableId);
    if (!response) return;
    closeOnSubmit();
  }, [closeOnSubmit, remove, variableId]);

  return (
    <ModalSkeleton
      title="Excluir vínculo de dashboard"
      opened={opened}
      onCancel={onClose}
      buttonText="Excluir"
      onDelete={onRemove}
      loading={loading}
      isErrorButton
    >
      <S.ModalWrapper>
        <Typography
          variant="h5"
          style={{ fontSize: '1.8rem', fontWeight: 500 }}
        >
          Realmente deseja excluir este vínculo?
        </Typography>
        <S.Typography variant="h5">
          Este cliente perderá acesso a esta dashboard.
        </S.Typography>
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

DeleteDashModal.defaultProps = {
  opened: false,
  onClose: () => null,
  remove: () => null,
  closeOnSubmit: () => null,
  variableId: '',
  loading: false,
};

DeleteDashModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  remove: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  variableId: PropTypes.string,
  loading: PropTypes.bool,
};
export default DeleteDashModal;
