import { Box as MaterialBox } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Box = styled(MaterialBox)`
  > div + div {
    margin-left: 3.5rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: unset;
`;

export const CardBody = styled.div`
  user-select: unset;

  h1 {
    font-weight: bolder;
  }
`;

export const Card = styled(MaterialBox)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.3rem 0.6rem #00000029;
    padding: 2rem 3rem;
    user-select: unset;
    width: 100%;
  `}
`;

export const Img = styled.img`
  ${({ theme }) => css`
    user-select: unset;
    margin-right: 1rem;
    background-color: ${theme.customColor.violet.a10};
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    padding: 1rem;
  `}
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 3rem 0 2rem;

  > div {
    display: flex;
    align-items: center;
  }

  > button {
    margin-top: 2rem;
  }
`;

export const InputsWrapper = styled.div`
  width: 100%;
  margin-left: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2.2rem;
`;
