import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { Avatar } from '../../..';
import setObjectToFormData from '../../../../helpers/setObjectToFormData';
import CreateClient from '../../../../validations/CreateClient';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const CreateClientModal = ({
  opened,
  onClose,
  create,
  closeOnSubmit,
  companyId,
  companyName,
  loading,
}) => {
  const formData = useMemo(() => new FormData(), []);
  const {
    font: { sizes },
    typography,
  } = useTheme();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateClient),
  });

  const onSubmit = useCallback(
    async data => {
      setObjectToFormData(formData, data);
      formData.set('companyId', companyId);
      const response = await create(formData);
      if (!response) return;
      reset();
      closeOnSubmit();
    },
    [closeOnSubmit, companyId, create, formData, reset],
  );

  const onChangeAvatar = file => {
    formData.set('avatar', file);
  };

  useEffect(() => {
    reset({ name: '', companyId: companyName });
  }, [companyName, reset]);

  return (
    <ModalSkeleton
      title="Cadastrar novo Cliente"
      opened={opened}
      onCancel={onClose}
      buttonText="Cadastrar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <S.AvatarWrapper>
          <Avatar onChange={onChangeAvatar} change size="80" round=".6rem" />
          <Typography style={{ fontSize: sizes.large, color: typography.gray }}>
            Logo cliente
          </Typography>
        </S.AvatarWrapper>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="Nome do cliente"
              label="Nome do cliente*"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.name?.message} />
        <Controller
          name="companyId"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              label="Companhia"
              disabled
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.companyId?.message} />
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

CreateClientModal.defaultProps = {
  opened: false,
  onClose: () => null,
  create: () => null,
  closeOnSubmit: () => null,
  companyId: '',
  companyName: '',
  loading: false,
};

CreateClientModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  create: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  loading: PropTypes.bool,
};

export default CreateClientModal;
