import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useAPI from '../hooks/useApi';
import { getUserId } from '../services/auth';
import { getUserById } from '../services/user';

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const callAPI = useAPI();

  const refreshUser = useCallback(async () => {
    const userId = getUserId();
    if (userId) {
      const response = await callAPI(getUserById, userId);
      setUser(response);
    }
  }, [callAPI]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider, useUser };
