import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import setObjectToFormData from '../../../../helpers/setObjectToFormData';
import useAPI from '../../../../hooks/useApi';
import { getUserById as getUser } from '../../../../services/user';
import EditUser from '../../../../validations/EditUser';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const EditUserModal = ({
  opened,
  onClose,
  variableId: userId,
  edit,
  submitClose,
  loading,
}) => {
  const formData = useMemo(() => new FormData(), []);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(EditUser),
  });

  const [user, setUser] = useState(null);
  const callAPI = useAPI();

  const getUserById = useCallback(async () => {
    const response = await callAPI(getUser, userId);

    if (response) {
      setUser(response);
    }
  }, [callAPI, userId]);

  useEffect(() => {
    getUserById();
  }, [getUserById]);

  useEffect(() => {
    reset({ name: user?.name, email: user?.email });
  }, [user, reset]);

  const onSubmit = useCallback(
    async data => {
      setObjectToFormData(formData, data);
      formData.delete('company');
      const response = await edit({
        bodyParams: formData,
        userId,
      });
      if (!response) return;
      reset();
      submitClose();
    },
    [edit, formData, reset, submitClose, userId],
  );

  return (
    <ModalSkeleton
      title="Editar usuário"
      opened={opened}
      onCancel={onClose}
      buttonText="Salvar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <Controller
          name="name"
          control={control}
          defaultValue={user?.name || ''}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="Nome"
              label="Nome*"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.name?.message} />
        <Controller
          name="email"
          control={control}
          defaultValue={user?.email || ''}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="E-mail"
              label="E-mail*"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.email?.message} />
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

EditUserModal.defaultProps = {
  opened: false,
  onClose: () => null,
  variableId: '',
  edit: () => null,
  submitClose: () => null,
  loading: false,
};

EditUserModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  variableId: PropTypes.string,
  edit: PropTypes.func,
  submitClose: PropTypes.func,
  loading: PropTypes.bool,
};

export default EditUserModal;
