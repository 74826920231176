import {
  Box as MaterialBox,
  FormControlLabel as MaterialFormControlLabel,
  MenuItem as MaterialMenuItem,
  Select as MaterialSelect,
  TextField as TextFieldMaterial,
  Typography as TypographyMaterial,
} from '@material-ui/core';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

export const TextField = styled(TextFieldMaterial)`
  width: 72rem;

  .MuiOutlinedInput-input {
    font-size: 1.6rem;
  }
`;

export const Typography = styled(TypographyMaterial).attrs({
  style: {
    fontSize: '1.6rem',
    fontWeight: 400,
    color: defaultTheme.typography.gray,
  },
})``;

export const ModalWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > div + div,
  > .MuiTypography-root + .MuiTypography-root {
    margin-top: 2rem;
  }

  .MuiSvgIcon-root {
    width: 3rem;
    height: 3rem;
  }

  .MuiInputLabel-root {
    font-size: 1.6rem;
    background: ${defaultTheme.colors.white};
    padding-right: 0.5rem;
  }
`;

export const MenuItem = styled(MaterialMenuItem).attrs({
  style: {
    fontSize: '1.6rem',
  },
})``;

export const Wrapper = styled.div`
  margin: 1rem 1rem;
  padding: 2rem 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  width: 97.5%;
  > div {
    width: 12%;
    margin: 0 1rem;
  }

  > div:first-child {
    width: 15%;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-bottom: none;
    border-left: none;
    border-top: none;
    margin-right: 7%;
  }

  > div:last-child {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Title = styled(TypographyMaterial)`
  font-size: ${({ subtitle }) => (subtitle ? '1.2rem' : '1.4rem')};
  font-weight: ${({ light }) => (light ? 400 : 600)}!important;
`;

export const Checkbox = styled.input`
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;

  > div + div {
    margin-left: 5rem;
  }
`;

export const FormControlLabel = styled(MaterialFormControlLabel)`
  .MuiTypography-body1 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0.1rem;
    opacity: 0.6;
    cursor: default;
  }
`;

export const Select = styled(MaterialSelect).attrs({
  style: {
    fontSize: '1.6rem',
  },
})``;

export const Box = styled(MaterialBox)`
  > button {
    text-transform: none;
    border: 0.1rem solid transparent;
  }
  > button + button {
    margin-left: 2rem;
  }

  > button:first-child {
    background-color: ${defaultTheme.colors.primary};
    border-color: ${defaultTheme.colors.primary};
  }
  > button:last-child {
    background-color: ${defaultTheme.colors.red};
    border-color: ${defaultTheme.colors.red};
  }
`;

export const AtentionWrapper = styled.div``;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 2rem;
  }
`;
