import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RiSave3Line as SAVE_ICON } from 'react-icons/ri';
import { useTheme } from 'styled-components';
import CLOSE_ICON from '../../assets/icon/closeIcon.svg';
import * as S from './styled';

const ModalSkeleton = ({
  opened,
  title,
  secondTitle,
  children,
  secondChildren,
  buttonText,
  isErrorButton,
  onSend,
  onDelete,
  onCancel,
  buttonsDisabled,
  loading,
  minSizeBig,
  upModal,
  buttonIcon,
}) => {
  const { colors } = useTheme();
  const buttonType = {
    true: { type: 'button', color: 'secondary', onClick: onDelete },
    false: {
      type: 'submit',
      color: 'primary',
      startIcon: buttonIcon || <SAVE_ICON size="2rem" />,
    },
  };

  return (
    <S.Wrapper upModal={upModal}>
      <S.Modal open={opened}>
        <S.ModalWrapper onSubmit={onSend} minSizeBig={minSizeBig}>
          <S.Header>
            <Typography
              variant="h3"
              style={{ fontSize: '2.1rem', fontWeight: 'bold' }}
            >
              {title}
            </Typography>
            <IconButton onClick={onCancel}>
              <S.CloseIcon
                src={CLOSE_ICON}
                alt="x representando botão fechar"
              />
            </IconButton>
          </S.Header>
          <S.Body>{children}</S.Body>
          <S.Footer>
            <S.FooterButton
              text="Cancelar"
              variant="outlined"
              onClick={onCancel}
              cancel
              disabled={buttonsDisabled}
              style={{ borderColor: colors.gray, color: colors.gray }}
            />
            <S.FooterButton
              text={buttonText}
              variant="contained"
              loading={loading}
              disabled={buttonsDisabled}
              {...buttonType[isErrorButton]}
              style={{ marginLeft: '1.5rem' }}
            />
          </S.Footer>
          {secondChildren && (
            <>
              <Box py={3}>
                <Divider py={3} />
              </Box>
              <Typography
                variant="h3"
                style={{ fontSize: '2.1rem', fontWeight: 'bold' }}
              >
                {secondTitle}
              </Typography>
              <S.CardsWrapper>{secondChildren}</S.CardsWrapper>
            </>
          )}
        </S.ModalWrapper>
      </S.Modal>
    </S.Wrapper>
  );
};

ModalSkeleton.defaultProps = {
  title: 'Título do modal',
  secondTitle: 'Título do modal',
  children: null,
  secondChildren: null,
  buttonText: 'Salvar',
  isErrorButton: false,
  buttonsDisabled: false,
  loading: false,
  minSizeBig: false,
  onDelete: () => null,
  onSend: () => null,
  upModal: false,
  buttonIcon: null,
};

ModalSkeleton.propTypes = {
  opened: PropTypes.bool.isRequired,
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  children: PropTypes.node,
  secondChildren: PropTypes.node,
  buttonText: PropTypes.string,
  isErrorButton: PropTypes.bool,
  onSend: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  buttonsDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  minSizeBig: PropTypes.bool,
  upModal: PropTypes.bool,
  buttonIcon: PropTypes.node,
};

export default ModalSkeleton;
