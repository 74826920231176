import PropTypes from 'prop-types';
import CompanyModals from './CompanyModals';
import ClientModals from './ClientModals';
import UserModals from './UserModals';
import DashModals from './DashModals';

const Modal = ({ type, action, ...props }) => {
  const typeMap = {
    company: <CompanyModals action={action} {...props} />,
    client: <ClientModals action={action} {...props} />,
    user: <UserModals action={action} {...props} />,
    dash: <DashModals action={action} {...props} />,
  };

  return typeMap[type];
};

Modal.defaultProps = {
  type: 'company',
  action: 'create',
};

Modal.propTypes = {
  type: PropTypes.oneOf(['company', 'client', 'user', 'dash']),
  action: PropTypes.oneOf(['create', 'edit', 'remove', 'permissions']),
};

export default Modal;
