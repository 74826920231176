import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import CreateDash from '../../../../validations/CreateDash';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const CreateDashModal = ({
  opened,
  onClose,
  create,
  closeOnSubmit,
  clientId,
  loading,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateDash),
  });

  const onSubmit = useCallback(
    async data => {
      const bodyParams = {
        name: data.name,
        url: data.url,
        clientId,
      };
      const response = await create(bodyParams);
      if (!response) return;
      reset();
      closeOnSubmit();
    },
    [clientId, closeOnSubmit, create, reset],
  );

  return (
    <ModalSkeleton
      title="Cadastrar nova dashboard"
      opened={opened}
      onCancel={onClose}
      buttonText="Cadastrar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <S.TextField
          variant="outlined"
          placeholder="Nome da Dashboard"
          label="Nome da Dashboard*"
          {...register('name')}
        />
        <ErrorMessage message={errors.name?.message} />
        <S.TextField
          variant="outlined"
          placeholder="URL"
          label="URL*"
          {...register('url')}
        />
        <ErrorMessage message={errors.url?.message} />
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

CreateDashModal.defaultProps = {
  opened: false,
  onClose: () => null,
  create: () => null,
  closeOnSubmit: () => null,
  clientId: '',
  loading: false,
};

CreateDashModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  create: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  clientId: PropTypes.string,
  loading: PropTypes.bool,
};

export default CreateDashModal;
