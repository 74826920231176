import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import formatName from '../../helpers/formatName';
import { useUser } from '../../providers/user';
import { defaultTheme } from '../../styles/defaultTheme';
import Avatar from '../Avatar';
import * as S from './styled';

const Header = ({ logoSrc, logoAlt, ...props }) => {
  const { colors } = useTheme();
  const { user } = useUser();

  const { pathname } = useLocation();

  const profileActive = pathname === '/profile';

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      component={Grid}
      bgcolor={defaultTheme.colors.primary}
      display="flex"
      height="6rem"
      width="100%"
      py={1}
      px={3}
      item
      {...props}
    >
      <S.Img src={logoSrc} alt={logoAlt} />
      <S.RightMenu profileActive={profileActive}>
        <S.ProfileButton to="/profile">Meu perfil</S.ProfileButton>
        <S.Divider />
        <Typography
          style={{
            fontSize: '1.9rem',
            color: colors.white,
            marginRight: '1.6rem',
          }}
        >
          {formatName(user.name || 'Carregando...')}
        </Typography>
        <Avatar name={user.name} avatarPath={user.avatarUrl} size="45" round />
      </S.RightMenu>
    </Box>
  );
};

Header.defaultProps = {
  logoSrc: '',
  logoAlt: '',
};

Header.propTypes = {
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
};

export default Header;
