import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import * as S from '../styled';

const Password = ({ name, control, label, errors, title, value, disabled }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const changePasswordVisibility = () => {
    setPasswordVisibility(prevState => !prevState);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const renderField = useCallback(
    () =>
      control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <OutlinedInput
              fullWidth
              disabled={disabled}
              {...field}
              id="outlined-adornment-password"
              type={passwordVisibility ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={changePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
      ) : (
        <OutlinedInput
          disabled={disabled}
          value={value}
          id="outlined-adornment-password"
          type={passwordVisibility ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={changePasswordVisibility}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passwordVisibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
        />
      ),
    [control, name, passwordVisibility, value, disabled],
  );

  return (
    <S.PasswordWrapper>
      {title && <S.Title>{title}</S.Title>}
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        {renderField()}
      </FormControl>
      {errors && <S.ErrorMessage>{errors}</S.ErrorMessage>}
    </S.PasswordWrapper>
  );
};

export default Password;

Password.defaultProps = {
  name: '',
  control: null,
  label: null,
  errors: '',
  title: '',
  value: '',
  disabled: false,
};

Password.propTypes = {
  name: PropTypes.string,
  control: PropTypes.node,
  label: PropTypes.string,
  errors: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};
