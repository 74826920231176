import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { defaultTheme } from '../../../styles/defaultTheme';
import * as S from '../styled';
import BaseProps from '../types/base.prop';

const Text = ({ name, control, label, errors, title, disabled }) => {
  const customStyles = disabled
    ? { backgroundColor: defaultTheme.colors.gray, borderRadius: 5 }
    : {};

  return (
    <S.InputWrapper title={title} disabled={disabled}>
      {title && <S.Title>{title}</S.Title>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            disabled={disabled}
            id="outlined-required"
            variant="outlined"
            label={label}
            fullWidth
            style={customStyles}
            {...field}
            value={field.value || ''}
          />
        )}
      />
      {errors && <S.ErrorMessage>{errors}</S.ErrorMessage>}
    </S.InputWrapper>
  );
};

Text.defaultProps = {
  disabled: false,
};

Text.propTypes = {
  disabled: PropTypes.bool,
  ...BaseProps.propTypes,
};

export default Text;
