import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Companies,
  CompaniesDetails,
  CompaniesDetailsDashs,
  Home,
  Login,
  NotFound,
  Profile,
} from '../pages';
import PrivateRoute from './privateRoutes';

const Routes = () => (
  <Router>
    <Switch>
      <Route component={Login} path="/" exact />
      <PrivateRoute path="/home" component={Home} exact />
      <PrivateRoute path="/companies" component={Companies} exact />
      <PrivateRoute path="/profile" component={Profile} exact />
      <PrivateRoute
        path="/companies/details/:companyId"
        component={CompaniesDetails}
        exact
      />
      <PrivateRoute
        path="/companies/details/:companyId/dashs/:dashId"
        component={CompaniesDetailsDashs}
        exact
      />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
