import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import setObjectToFormData from '../../../../helpers/setObjectToFormData';
import EditCompany from '../../../../validations/EditCompany';
import Avatar from '../../../Avatar';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const EditCompanyModal = ({
  opened,
  onClose,
  edit,
  variableId,
  closeOnSubmit,
  defaultEditValue,
  getCompanyById,
  loading,
}) => {
  const formData = useMemo(() => new FormData(), []);
  const {
    font: { sizes },
    typography,
  } = useTheme();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(EditCompany),
  });

  useEffect(() => {
    reset({ name: defaultEditValue.name });
  }, [defaultEditValue, reset]);

  useEffect(() => {
    getCompanyById(variableId);
  }, [getCompanyById, variableId]);

  const onSubmit = useCallback(
    async data => {
      setObjectToFormData(formData, data);
      const response = await edit(formData, variableId);
      if (!response) return;
      reset();
      closeOnSubmit();
    },
    [closeOnSubmit, edit, formData, reset, variableId],
  );

  const onChangeAvatar = file => {
    formData.set('avatar', file);
  };

  return (
    <ModalSkeleton
      title="Editar companhia"
      opened={opened}
      onCancel={onClose}
      buttonText="Salvar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <S.AvatarWrapper>
          <Avatar
            name={defaultEditValue.name}
            avatarPath={defaultEditValue.avatarUrl}
            onChange={onChangeAvatar}
            change
            size="80"
            round=".6rem"
          />
          <Typography style={{ fontSize: sizes.large, color: typography.gray }}>
            Logo da companhia
          </Typography>
        </S.AvatarWrapper>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="Nome da companhia"
              label="Nome da companhia*"
              InputLabelProps={{
                shrink: true,
              }}
              {...field}
            />
          )}
        />
      </S.ModalWrapper>
      <ErrorMessage message={errors.name?.message} />
    </ModalSkeleton>
  );
};

EditCompanyModal.defaultProps = {
  opened: false,
  onClose: () => null,
  edit: () => null,
  closeOnSubmit: () => null,
  variableId: null,
  defaultEditValue: '',
  getCompanyById: () => null,
  loading: false,
};

EditCompanyModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  variableId: PropTypes.node,
  defaultEditValue: PropTypes.string,
  getCompanyById: PropTypes.func,
  loading: PropTypes.bool,
};

export default EditCompanyModal;
