import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { Avatar } from '../../..';
import setObjectToFormData from '../../../../helpers/setObjectToFormData';
import CreateCompany from '../../../../validations/CreateCompany';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const CreateCompanyModal = ({
  opened,
  onClose,
  create,
  closeOnSubmit,
  loading,
}) => {
  const formData = useMemo(() => new FormData(), []);
  const {
    font: { sizes },
    typography,
  } = useTheme();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateCompany),
  });

  const onSubmit = useCallback(
    async data => {
      setObjectToFormData(formData, data);
      const response = await create(formData);
      if (!response) return;
      reset();
      closeOnSubmit();
    },
    [closeOnSubmit, create, formData, reset],
  );

  const onChangeAvatar = file => {
    formData.set('avatar', file);
  };

  return (
    <ModalSkeleton
      title="Cadastrar nova Companhia"
      opened={opened}
      onCancel={onClose}
      buttonText="Cadastrar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <S.AvatarWrapper>
          <Avatar onChange={onChangeAvatar} change size="80" round=".6rem" />
          <Typography style={{ fontSize: sizes.large, color: typography.gray }}>
            Logo da companhia
          </Typography>
        </S.AvatarWrapper>
        <S.TextField
          variant="outlined"
          placeholder="Nome da companhia"
          label="Nome da companhia*"
          {...register('name')}
        />
        <ErrorMessage message={errors.name?.message} />
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

CreateCompanyModal.defaultProps = {
  opened: false,
  onClose: () => null,
  create: () => null,
  closeOnSubmit: () => null,
  loading: false,
};

CreateCompanyModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  create: PropTypes.func,
  closeOnSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default CreateCompanyModal;
