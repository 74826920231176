import styled, { css } from 'styled-components';
import Link from '../Link';

export const Img = styled.img`
  height: 30px;
`;

export const RightMenu = styled.div`
  ${({ theme, profileActive }) => css`
    display: flex;
    align-items: center;

    > a {
      color: ${theme.colors.white};
      text-transform: none;
      font-size: 1.9rem;
      height: 5rem;
      display: flex;
      align-items: center;

      ${profileActive &&
      css`
        &::after {
          content: '';
          width: 50%;
          height: 0.2rem;
          background-color: ${theme.colors.white};
          position: absolute;
          bottom: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
        }
      `}
    }
  `}
`;

export const ProfileButton = styled(Link)``;

export const Divider = styled.div`
  ${({ theme }) => css`
    height: 3.6rem;
    border-right: 1px solid ${theme.colors.white};
    margin: 0 1.6rem;
  `}
`;
