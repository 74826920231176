import styled, { css } from 'styled-components';
import { Box as MaterialBox } from '@material-ui/core';

export const Box = styled(MaterialBox)`
  user-select: none;
  cursor: ${({ arrow }) => (arrow ? 'pointer' : 'initial')};
`;

export const Img = styled.img`
  width: 15px;
  margin: 0 10px;
  ${({ active }) =>
    active &&
    css`
      transform: rotate(180deg);
      transition: all 0.7s;
    `}
  transition: all 0.7s;
  user-select: none;
`;

export const Divider = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-bottom: none;
  border-left: none;
  margin-left: 7px;
`;
