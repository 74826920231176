import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import { Snackbar } from './components';
import { SnackbarProvider } from './providers/snackbar';
import { UserProvider } from './providers/user';
import Routes from './routes/routes';
import { defaultTheme } from './styles/defaultTheme';
import GlobalStyles from './styles/global';
import { materialTheme } from './styles/material.theme';

function App() {
  return (
    <HelmetProvider>
      <MaterialThemeProvider theme={materialTheme}>
        <ThemeProvider theme={defaultTheme}>
          <UserProvider>
            <SnackbarProvider>
              <GlobalStyles />
              <Snackbar />
              <Routes />
            </SnackbarProvider>
          </UserProvider>
        </ThemeProvider>
      </MaterialThemeProvider>
    </HelmetProvider>
  );
}

export default App;
