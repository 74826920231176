import PropTypes from 'prop-types';
import * as S from './styled';

const ErrorMessage = ({ padding, message, fontSize }) => (
  <S.Wrapper paddingSpace={padding} textSize={fontSize}>
    <p>{message}</p>
  </S.Wrapper>
);

ErrorMessage.defaultProps = {
  padding: '1rem 1rem 1rem 0',
  fontSize: '1.5rem',
  message: null,
};

ErrorMessage.propTypes = {
  message: PropTypes.node,
  fontSize: PropTypes.string,
  padding: PropTypes.string,
};

export default ErrorMessage;
