import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddFill as PlusIcon,
  RiDeleteBin7Line as DeleteIcon,
  RiFolderLine as FolderIcon,
  RiPencilLine as PencilIcon,
} from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '../../Modal';
import * as S from '../styled';
import TableTitle from '../TableTitle';

const ClientTable = ({
  data,
  create,
  edit,
  remove,
  getById,
  defaultEditValue,
  companyId,
  companyName,
  getAll,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalState, setModalState] = useState(false);
  const [modalType, setModalType] = useState('create');
  const [clientIdState, setClientIdState] = useState(null);
  const [list, setList] = useState();
  const [inputValue, setInputValue] = useState('');

  const redirect = useHistory();
  const { companyId: companyUrl } = useParams();
  const closeModal = () => setModalState(false);

  useEffect(() => {
    setList(data.result);
  }, [data]);

  useEffect(() => {
    getAll(inputValue, companyUrl, { skip: page, take: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, getAll, companyUrl, page]);

  const columns = [
    { id: 'clientName', label: 'Nome do cliente', width: '27%', arrow: true },

    {
      id: 'quantityOfDashs',
      label: 'Quantidade de Dashboards',
      width: '27%',
      arrow: true,
    },
    { id: 'actions', label: 'Ações', width: '18%', arrow: false },
  ];

  const [orderState, setOrderState] = useState({
    clientName: false,
    quantityOfDashs: false,
    actions: false,
  });

  const returnModal = action => (
    <Modal
      type="client"
      action={action}
      create={create}
      edit={edit}
      remove={remove}
      getById={getById}
      defaultEditValue={defaultEditValue}
      variableId={clientIdState}
      companyId={companyId}
      companyName={companyName}
      opened={modalState}
      closeOnSubmit={closeModal}
      onClose={closeModal}
      loading={loading}
    />
  );

  const handleOrderChange = (id, value) => {
    if (value) {
      const stateCopy = { ...orderState };
      Object.keys(orderState).forEach(key => {
        stateCopy[key] = false;
      });
      stateCopy[id] = value;
      setOrderState(stateCopy);
    }
    orderUsersBy(id, value);
  };

  const orderUsersBy = (id, value) => {
    let dataCopy = [...data];
    dataCopy = dataCopy.sort((userA, userB) => {
      if (typeof userA[id] === 'string') {
        return value
          ? userB[id].localeCompare(userA[id])
          : userA[id].localeCompare(userB[id]);
      }
      return value ? userB[id] - userA[id] : userA[id] - userB[id];
    });
    setList(dataCopy);
  };

  function createDataTable(clientName, quantityOfDashs, actions) {
    return { clientName, quantityOfDashs, actions };
  }

  const openModal = (type, clientId) => {
    if (modalState) {
      setModalState(false);
    }
    setClientIdState(clientId);
    setModalType(type);
    setModalState(true);
  };

  const buttonActions = clientId => (
    <S.Box display="flex" three>
      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          redirect.push(`/companies/details/${companyUrl}/dashs/${clientId}`)
        }
        startIcon={<FolderIcon />}
      >
        Visualizar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PencilIcon />}
        onClick={() => openModal('edit', clientId)}
      >
        Editar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={() => openModal('remove', clientId)}
      >
        Excluir
      </Button>
    </S.Box>
  );

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = useCallback(
    e => {
      e.preventDefault();

      getAll(inputValue, companyUrl, { skip: page, take: rowsPerPage });
    },
    [companyUrl, getAll, inputValue, page, rowsPerPage],
  );

  const fillTable = list
    ? list.map(element =>
        createDataTable(element.name, element.dashs, buttonActions(element.id)),
      )
    : [];

  return (
    <>
      {returnModal(modalType)}
      <S.Paper elevation={2}>
        <Box display="flex" flexDirection="column" p={2}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box width="25%">
              <Box bgcolor="rgba(245,245,245)" borderRadius="5px" px={1}>
                <form onSubmit={handleSearch}>
                  <Box display="flex" alignItems="center">
                    <InputBase
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      placeholder="Pesquisar"
                      fullWidth
                    />
                    <Divider orientation="vertical" />
                    <IconButton type="submit" aria-label="search">
                      <S.SearchIcon />
                    </IconButton>
                  </Box>
                </form>
              </Box>
            </Box>
            <S.TitleButton>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PlusIcon />}
                onClick={() => openModal('create')}
              >
                Cadastrar novo cliente
              </Button>
            </S.TitleButton>
          </Box>
          <Box py={3}>
            <Divider />
          </Box>

          <S.TableStyle>
            <S.TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map(columnTitle => (
                      <TableTitle
                        key={columnTitle.id}
                        id={columnTitle.id}
                        orderChange={handleOrderChange}
                        order={orderState[columnTitle.id]}
                        hasArrow={columnTitle.arrow}
                        style={{
                          width: columnTitle.width,
                        }}
                      >
                        {columnTitle.label}
                      </TableTitle>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <S.LoadingTable cols={3} />
                  ) : (
                    fillTable?.map(row => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </S.TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
            />
          </S.TableStyle>
        </Box>
      </S.Paper>
    </>
  );
};

ClientTable.defaultProps = {
  data: [],
  companyId: '',
  create: () => null,
  edit: () => null,
  getById: () => null,
  remove: () => null,
  defaultEditValue: () => null,
  companyName: '',
  getAll: () => null,
  loading: false,
};

ClientTable.propTypes = {
  data: PropTypes.array,
  companyId: PropTypes.string,
  create: PropTypes.func,
  edit: PropTypes.func,
  getById: PropTypes.func,
  defaultEditValue: PropTypes.func,
  remove: PropTypes.func,
  companyName: PropTypes.string,
  getAll: PropTypes.func,
  loading: PropTypes.bool,
};

export default ClientTable;
