import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    background-color: ${theme.colors.transparent};
    margin: 0.5rem;
  `}

  ${({ title }) =>
    !!title &&
    css`
      .MuiOutlinedInput-input {
        padding: 1rem 1.4rem;
      }
    `}


  .MuiInputBase-input {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }
`;

export const DefaultFontStyle = css`
  margin: 10px 0;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    ${DefaultFontStyle};
    font-weight: ${theme.font.bold};
    color: ${theme.alert.error};
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${DefaultFontStyle};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.black};
  `}
`;

export const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;

  margin: 0 2rem;
`;
