import PropTypes from 'prop-types';
import CreateDashModal from './CreateDashModal';
import EditDashModal from './EditDashModal';
import DeleteDashModal from './DeleteDashModal';

const DashModals = ({ action, create, edit, remove, ...props }) => {
  const actionMap = {
    create: <CreateDashModal create={create} {...props} />,
    edit: <EditDashModal edit={edit} {...props} />,
    remove: <DeleteDashModal remove={remove} {...props} />,
  };

  return actionMap[action];
};

DashModals.defaultProps = {
  action: 'create',
  create: () => null,
  edit: () => null,
  remove: () => null,
};

DashModals.propTypes = {
  action: PropTypes.oneOf(['create', 'edit', 'remove']),
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
};

export default DashModals;
