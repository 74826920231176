import { Box as MaterialBox } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Box = styled(MaterialBox)`
  > div + div {
    margin-left: 3.5rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: unset;
`;

export const CardBody = styled.div`
  user-select: unset;

  h1 {
    font-weight: bolder;
  }
`;

export const Card = styled(MaterialBox)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20rem;
    width: 30rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.3rem 0.6rem #00000029;
    padding: 2rem 3rem;
    user-select: unset;
  `}
`;

export const Img = styled.img`
  user-select: unset;
  margin-right: 1rem;
  background-color: ${defaultTheme.customColor.violet.a10};
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  padding: 1rem;
`;
