import PropTypes from 'prop-types';
import * as S from './styled';

const Link = ({ to, children }) => <S.Router to={to}>{children}</S.Router>;

Link.defaultProps = {
  to: '/',
  children: null,
};

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default Link;
