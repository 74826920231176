import { API } from './api';

export const getAllDashs = async ({
  clientId,
  searchValue = '',
  skip,
  take,
}) => {
  const api = await API({ auth: true, refresh: true });

  const searchString = searchValue ? `&searchValue=${searchValue}` : '';
  const paginateOptions = skip || take ? `&skip=${skip}&take=${take}` : '';

  return api.get(`/dash?clientId=${clientId}${searchString}${paginateOptions}`);
};

export const getDashById = async dashId => {
  const api = await API({ auth: true, refresh: true });
  return api.get(`/dash/${dashId}`);
};

export const postCreateDash = async bodyParams => {
  const api = await API({ auth: true, refresh: true });
  return api.post(`/dash`, bodyParams);
};

export const patchEditDash = async ({ bodyParams, dashId }) => {
  const api = await API({ auth: true, refresh: true });
  return api.patch(`/dash/${dashId}`, bodyParams);
};

export const deleteDash = async dashId => {
  const api = await API({ auth: true, refresh: true });
  return api.delete(`/dash/${dashId}`);
};

export const getDashLength = async () => {
  const api = await API({ auth: true, refresh: true });
  return api.get('/dash/length');
};
