import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ALERT_ICON from '../../../../assets/icon/alertIcon.svg';
import { defaultTheme } from '../../../../styles/defaultTheme';
import EditDash from '../../../../validations/EditDash';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const EditDashModal = ({
  opened,
  onClose,
  edit,
  variableId,
  closeOnSubmit,
  defaultEditValue,
  getDashById,
  loading,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditDash),
  });

  const onSubmit = useCallback(
    async data => {
      const params = {
        name: data.name,
        url: data.url,
      };
      const response = await edit(params, variableId);
      if (!response) return;
      reset();
      closeOnSubmit();
    },
    [closeOnSubmit, edit, reset, variableId],
  );

  useEffect(() => {
    reset({ name: defaultEditValue.name, url: defaultEditValue.url });
  }, [defaultEditValue, reset]);

  useEffect(() => {
    getDashById(variableId);
  }, [getDashById, variableId]);

  return (
    <ModalSkeleton
      title="Editar companhia"
      opened={opened}
      onCancel={onClose}
      buttonText="Salvar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <Controller
          name="name"
          control={control}
          defaultValue={defaultEditValue.name || ''}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="Nome do dash"
              label="Nome do dash*"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.name?.message} />
        <Controller
          name="url"
          control={control}
          defaultValue={defaultEditValue.url || ''}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="URL"
              label="URL*"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.url?.message} />
        <S.AtentionWrapper>
          <Box
            display="flex"
            alignItems="center"
            color={defaultTheme.colors.warning}
            py={1}
          >
            <img src={ALERT_ICON} alt="" />
            <Box px={1}>
              <Typography variant="h6">Atenção</Typography>
            </Box>
          </Box>
          <Box color="rgba(0,0,0,0.5)">
            <Typography variant="h6">
              Caso seja alterado a URL da dashboard, o usuário irá perder o
              acesso a URL informada anteriormente.
            </Typography>
          </Box>
        </S.AtentionWrapper>
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

EditDashModal.defaultProps = {
  opened: false,
  onClose: () => null,
  edit: () => null,
  variableId: '',
  closeOnSubmit: () => null,
  getDashById: () => null,
  defaultEditValue: null,
  loading: false,
};

EditDashModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.func,
  variableId: PropTypes.string,
  closeOnSubmit: PropTypes.func,
  getDashById: PropTypes.func,
  defaultEditValue: PropTypes.node,
  loading: PropTypes.bool,
};

export default EditDashModal;
