import { API } from './api';

export const getAllClients = async ({ searchValue, companyId, skip, take }) => {
  const api = await API({ auth: true, refresh: true });

  const searchString = searchValue ? `&searchValue=${searchValue}` : '';
  const paginateOptions = skip || take ? `&skip=${skip}&take=${take}` : '';

  return api.get(
    `client/?companyId=${companyId}${searchString}${paginateOptions}&isBackOffice=true`,
  );
};

export const getClientById = async clientId => {
  const api = await API({ auth: true, refresh: true });
  return api.get(`/client/${clientId}`);
};

export const postCreateClient = async bodyParams => {
  const api = await API({ auth: true, refresh: true, formData: true });
  return api.post('/client', bodyParams);
};

export const patchEditClient = async ({ bodyParams, clientId }) => {
  const api = await API({ auth: true, refresh: true, formData: true });
  return api.patch(`/client/${clientId}`, bodyParams);
};

export const deleteClient = async clientId => {
  const api = await API({ auth: true, refresh: true });
  return api.delete(`/client/${clientId}`);
};
