import PropTypes from 'prop-types';
import CreateCompanyModal from './CreateCompanyModal';
import EditCompanyModal from './EditCompanyModal';
import DeleteCompanyModal from './DeleteCompanyModal';

const UserModals = ({ action, create, edit, remove, ...props }) => {
  const actionMap = {
    create: <CreateCompanyModal create={create} {...props} />,
    edit: <EditCompanyModal edit={edit} {...props} />,
    remove: <DeleteCompanyModal remove={remove} {...props} />,
  };

  return actionMap[action];
};

UserModals.defaultProps = {
  action: 'create',
  create: () => null,
  edit: () => null,
  remove: () => null,
};

UserModals.propTypes = {
  action: PropTypes.oneOf(['create', 'edit', 'remove']),
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
};

export default UserModals;
