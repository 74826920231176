import { API } from './api';

export const getAllCompany = async ({ searchValue = '', skip, take }) => {
  const api = await API({ auth: true, refresh: true });

  const searchString = searchValue ? `&searchValue=${searchValue}` : '';
  const paginateOptions = skip || take ? `&skip=${skip}&take=${take}` : '';

  return api.get(`/company?${searchString}${paginateOptions}`);
};

export const getCompanyById = async companyId => {
  const api = await API({ auth: true, refresh: true });
  return api.get(`/company/${companyId}`);
};

export const postCreateCompany = async bodyParams => {
  const api = await API({ auth: true, refresh: true, formData: true });
  return api.post('/company', bodyParams);
};

export const patchEditCompany = async ({ bodyParams, companyId }) => {
  const api = await API({ auth: true, refresh: true, formData: true });
  return api.patch(`/company/${companyId}`, bodyParams);
};

export const deleteCompany = async companyId => {
  const api = await API({ auth: true, refresh: true });
  return api.delete(`/company/${companyId}`);
};
