import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from '..';
import * as S from './styled';

function Button({
  text,
  variant,
  color,
  type,
  fullWidth,
  loading,
  disabled,
  onClick,
  startIcon,
  endIcon,
  style,
}) {
  const buttonRef = useRef();
  const [width, setWidth] = useState(0);
  const buttonProps = type === 'submit' ? {} : { onClick };

  useEffect(() => {
    if (buttonRef.current) {
      setWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  return (
    <S.Button
      ref={buttonRef}
      width={width}
      variant={variant}
      color={color}
      type={type}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      startIcon={!loading ? startIcon : null}
      endIcon={!loading ? endIcon : null}
      style={style}
      {...buttonProps}
    >
      {loading ? <Spinner /> : text}
    </S.Button>
  );
}

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  type: 'button',
  fullWidth: false,
  loading: false,
  disabled: false,
  onClick: () => {},
  startIcon: null,
  endIcon: null,
  style: {},
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  style: PropTypes.object,
};

export default Button;
