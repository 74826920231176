import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    &.MuiTypography-root {
      font-weight: ${theme.font.regular};
      margin-bottom: 20px;
      color: ${theme.colors.darkGray};
    }
  `}
`;
