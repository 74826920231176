import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import COMPANIES_ICON from '../../assets/icon/companyIcon.svg';
import HOME_ICON from '../../assets/icon/homeIcon.svg';
import LOG_OUT_ICON from '../../assets/icon/logoutIcon.svg';
import { logout } from '../../services/auth';
import { defaultTheme } from '../../styles/defaultTheme';
import Link from '../Link';
import * as S from './styled';

const SideBar = ({ bgColor, ...props }) => {
  // const [user, setUser] = useState({});
  // const callAPI = useAPI();
  const { pathname: url } = useLocation();
  const redirect = useHistory();
  const checkUrlAndRedirect = redirectPath => redirectPath === url;

  // const { showSnackbar } = useSnackbar();

  const logoutUser = async () => {
    logout();
    redirect.push('/');
  };

  const superiorMenu = [
    { redirect: '/home', label: 'Início', imgSrc: HOME_ICON },
    { redirect: '/companies', label: 'Companhias', imgSrc: COMPANIES_ICON },
  ];

  const bottomMenu = [
    { onClick: logoutUser, label: 'Sair', imgSrc: LOG_OUT_ICON },
  ];

  // AGUARDANDO DESIGN

  // const onChangeAvatar = useCallback(
  //   async file => {
  //     const userId = await getUserId();

  //     const updateData = new FormData();
  //     updateData.append('avatar', file);

  //     const params = {
  //       bodyParams: updateData,
  //       userId,
  //       formData: true,
  //     };

  //     const response = await callAPI(patchEditUser, params);

  //     if (response) {
  //       showSnackbar({
  //         type: 'success',
  //         message: 'Alteração realizada com sucesso!',
  //       });
  //       return true;
  //     }
  //     showSnackbar({
  //       type: 'error',
  //       message: 'Não foi possível realizar a alteração!',
  //     });
  //     return false;
  //   },
  //   [callAPI, showSnackbar],
  // );

  // const setNewImage = useCallback(avatarUrl => {
  //   setUser(prev => ({ ...prev, avatarUrl }));
  // }, []);

  // const fetchUser = useCallback(async () => {
  //   const userId = await getUserId();
  //   const result = await callAPI(getUserById, userId);
  //   setUser(prev => ({ ...prev, ...result }));
  // }, [callAPI]);

  // useEffect(() => {
  //   fetchUser();
  // }, [fetchUser]);
  return (
    <S.Bar>
      <S.Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgcolor={defaultTheme.colors[bgColor]}
        {...props}
      >
        <S.MenuWrapper>
          {/* AGUARDANDO DESIGN */}
          {/* <Avatar
            name={user.name}
            avatarPath={user.avatarUrl}
            setFile={setNewImage}
            change
            size="100"
            onChange={onChangeAvatar}
          /> */}
          {superiorMenu.map((menu, index) => (
            <div key={index}>
              <Link to={menu.redirect}>
                <S.Item isActive={checkUrlAndRedirect(`${menu.redirect}`)}>
                  <img src={menu.imgSrc} alt="" />
                  <Typography variant="h6">{menu.label}</Typography>
                </S.Item>
              </Link>
            </div>
          ))}
        </S.MenuWrapper>
        <div>
          {bottomMenu.map((menu, index) => {
            if (menu.redirect) {
              return (
                <Link to={menu.redirect} key={index}>
                  <S.Item
                    $end
                    isActive={checkUrlAndRedirect(`${menu.redirect}`)}
                  >
                    <img src={menu.imgSrc} alt="" />
                    <Typography variant="h6">{menu.label}</Typography>
                  </S.Item>
                </Link>
              );
            }
            return (
              <S.Item
                $end
                isActive={checkUrlAndRedirect(`${menu.redirect}`)}
                key={index}
                onClick={menu.onClick}
              >
                <img src={menu.imgSrc} alt="" />
                <Typography variant="h6">{menu.label}</Typography>
              </S.Item>
            );
          })}
        </div>
      </S.Box>
    </S.Bar>
  );
};

SideBar.defaultProps = {
  bgColor: 'white',
};
SideBar.propTypes = {
  bgColor: PropTypes.string,
};

export default SideBar;
