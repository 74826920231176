import { createTheme } from '@material-ui/core';

export const materialTheme = createTheme({
  typography: {
    allVariants: {
      fontSize: 'max(100%, 14px)',
    },
  },
  overrides: {
    MuiTab: {
      wrapper: {
        fontSize: '16px',
      },
    },
    MuiStepIcon: {
      root: {
        width: '25px',
        height: '25px',
        fontSize: '12px',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '100%',
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 6%), 0px 2px 2px 0px rgb(0 0 0 / 6%), 3px 3px 6px 0px rgb(0 0 0 / 6%)',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
});
