import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styled';

const Tab = ({ index, label, ...rest }) => <S.Tab {...rest} label={label} />;

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default Tab;
