import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ModalSkeleton from '../../../../ModalSkeleton';
import * as S from '../../../styled';

const DeleteModal = ({
  opened,
  onClose,
  variableId,
  remove,
  refreshPermissions,
  loading,
}) => {
  const onDelete = useCallback(async () => {
    const response = await remove(variableId);
    if (!response) return;
    refreshPermissions(variableId);
    onClose();
  }, [refreshPermissions, onClose, remove, variableId]);

  return (
    <ModalSkeleton
      title="Excluir permissão"
      opened={opened}
      onCancel={onClose}
      buttonText="Excluir"
      onDelete={onDelete}
      loading={loading}
      isErrorButton
    >
      <S.ModalWrapper>
        <Typography
          variant="h5"
          style={{ fontSize: '1.8rem', fontWeight: 500 }}
        >
          Realmente deseja excluir?
        </Typography>
        <S.Typography variant="h5">
          Esta permissão será excluída permanentemente.
        </S.Typography>
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

DeleteModal.defaultProps = {
  opened: false,
  onClose: () => null,
  remove: () => null,
  refreshPermissions: () => null,
  variableId: '',
  loading: false,
};

DeleteModal.propTypes = {
  opened: PropTypes.func,
  onClose: PropTypes.func,
  remove: PropTypes.func,
  refreshPermissions: PropTypes.func,
  variableId: PropTypes.string,
  loading: PropTypes.bool,
};

export default DeleteModal;
