export default function truncateString(string, numberOfVisibleCaracters) {
  let truncated = '';
  if (!string) return truncated;
  if (string.length > numberOfVisibleCaracters) {
    truncated = `${string.substring(0, numberOfVisibleCaracters)}...`;
  } else {
    truncated = string;
  }
  return truncated;
}
