/* eslint-disable react/prop-types */
import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoadingAnimation from '../assets/lottie/loading.json';
import { LottieSkeleton } from '../components';
import { checkAuth } from '../services/auth';

export default function PrivateRoutes({ component: Component, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const verifyAuth = useCallback(async () => {
    try {
      const isAuth = await checkAuth();
      setIsAuthenticated(isAuth);
    } catch (e) {
      // Deal with error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]);

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <LottieSkeleton animation={LoadingAnimation} />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}
