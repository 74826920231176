import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import EditUser from '../../../../validations/EditUser';
import ErrorMessage from '../../../ErrorMessage';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';

const CreateUserModal = ({ opened, onClose, create, submitClose, loading }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(EditUser),
  });

  const onSubmit = useCallback(
    async data => {
      const response = await create(data);
      if (!response) return;
      reset();
      submitClose();
    },
    [create, reset, submitClose],
  );

  return (
    <ModalSkeleton
      title="Cadastrar novo usuário"
      opened={opened}
      onCancel={onClose}
      buttonText="Cadastrar"
      loading={loading}
      onSend={handleSubmit(onSubmit)}
    >
      <S.ModalWrapper>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="Nome"
              label="Nome*"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.name?.message} />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <S.TextField
              variant="outlined"
              placeholder="E-mail"
              label="E-mail"
              {...field}
            />
          )}
        />
        <ErrorMessage message={errors.email?.message} />
      </S.ModalWrapper>
    </ModalSkeleton>
  );
};

CreateUserModal.defaultProps = {
  opened: false,
  onClose: () => null,
  create: () => null,
  submitClose: () => null,
  loading: false,
};

CreateUserModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  create: PropTypes.func,
  submitClose: PropTypes.func,
  loading: PropTypes.bool,
};

export default CreateUserModal;
