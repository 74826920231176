import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Paper, TextField, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import useApi from '../../hooks/useApi';
import { useSnackbar } from '../../providers/snackbar';
import { useUser } from '../../providers/user';
import { signIn } from '../../services/auth';
import LoginSchema from '../../validations/LoginSchema';
import * as S from './styled';

const Login = () => {
  const callAPI = useApi();
  const pathLocale = useHistory();
  const { refreshUser } = useUser();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const onSubmit = async data => {
    setLoading(true);
    const { email, password } = data;
    const response = await callAPI(signIn, { email, password });
    setLoading(false);
    if (response) {
      refreshUser();
      pathLocale.push('/home');
    } else {
      showSnackbar({
        type: 'error',
        message:
          'Não foi possível efetuar o login, verifique seu e-mail e/ou senha!',
      });
    }
  };

  return (
    <S.Wrapper>
      <S.Box>
        <Box>
          <Paper elevation={2}>
            <Box width="46rem" height="50rem" p={8}>
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h3">Olá</Typography>
                <Typography variant="h6">
                  Informe os dados de acesso abaixo e efetue seu login.
                </Typography>
                <div>
                  <TextField
                    fullWidth
                    label="E-mail de acesso"
                    variant="outlined"
                    type="email"
                    {...register('email')}
                    required
                  />
                  <p>{errors.email?.message}</p>
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Senha"
                    variant="outlined"
                    type="password"
                    {...register('password')}
                    required
                  />
                  <p>{errors.password?.message}</p>
                </div>
                <Button
                  loading={loading}
                  text="Acessar"
                  type="submit"
                  fullWidth
                />
              </S.Form>
            </Box>
          </Paper>
        </Box>
      </S.Box>
    </S.Wrapper>
  );
};

export default Login;
