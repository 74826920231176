import { Typography as MaterialTypograpy } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Content = styled.div`
  > h3 {
    font-weight: 500;
    padding-bottom: 3rem;
  }
`;

const wrapperColor = {
  default: css`
    color: ${defaultTheme.typography.lightGray};
    span {
      color: ${defaultTheme.typography.fourth};
    }
  `,
  primary: css`
    color: ${defaultTheme.typography.fourth};
  `,
};

export const ReturnButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${defaultTheme.colors.violet};
  background-color: #eaeaf8;
  cursor: pointer;
  user-select: unset;
  outline: none;

  :hover {
    opacity: 80%;
  }
`;

export const Typography = styled(MaterialTypograpy).attrs({
  style: {
    fontWeight: 500,
    userSelect: 'unset',
  },
})`
  padding: 0 2rem;
  user-select: unset;
  ${({ textColor }) => wrapperColor[textColor]}
`;
