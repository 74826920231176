import styled, { css } from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

export const StyledToolTip = styled.span`
  visibility: hidden;
  background-color: ${defaultTheme.colors.white};
  display: block;
  color: ${({ color }) => defaultTheme.colors[color]};
  border-radius: 0.6rem;
  padding: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  position: absolute;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0.3rem 0.6rem ${defaultTheme.colors.shadow};
  &:after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 2.5%;
    margin-left: 0.5rem;
    border-width: 0.7rem;
    border-style: solid;
    border-color: transparent transparent ${defaultTheme.colors.white}
      transparent;
  }
  ${css`
    @media (max-height: 900px) {
      font-size: 1.3rem;
    }
  `}
`;
