import PropTypes from 'prop-types';
import CreateClientModal from './CreateClientModal';
import EditClientModal from './EditClientModal';
import DeleteClientModal from './DeleteClientModal';

const ClientModals = ({ action, create, edit, remove, ...props }) => {
  const actionMap = {
    create: <CreateClientModal create={create} {...props} />,
    edit: <EditClientModal edit={edit} {...props} />,
    remove: <DeleteClientModal remove={remove} {...props} />,
  };

  return actionMap[action];
};

ClientModals.defaultProps = {
  action: 'create',
  create: () => null,
  edit: () => null,
  remove: () => null,
};

ClientModals.propTypes = {
  action: PropTypes.oneOf(['create', 'edit', 'remove']),
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
};

export default ClientModals;
