import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';

const LottieSkeleton = ({ animation, lottieWidth, lottieHeight }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie
        options={defaultOptions}
        height={lottieHeight}
        width={lottieWidth}
      />
    </div>
  );
};

LottieSkeleton.defaultProps = {
  lottieWidth: '20rem',
  lottieHeight: '16rem',
};

LottieSkeleton.propTypes = {
  animation: PropTypes.node.isRequired,
  lottieWidth: PropTypes.string,
  lottieHeight: PropTypes.string,
};

export default LottieSkeleton;
