import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageSkeleton, Tab, Table, TabPanel, Tabs } from '../../components';
import useApi from '../../hooks/useApi';
import { useSnackbar } from '../../providers/snackbar';
import { getClientById } from '../../services/clients';
import { getCompanyById } from '../../services/company';
import {
  deleteDash,
  getAllDashs as getAllDash,
  getDashById as dashById,
  patchEditDash,
  postCreateDash,
} from '../../services/dashs';

const CompaniesDetailsDashs = () => {
  const { dashId: clientId, companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [client, setClient] = useState({});
  const [defaultEditValue, setDefaultEditValue] = useState('');
  const [dashData, setDashData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_event, tab) => setTabValue(tab);
  const callAPI = useApi();

  const { showSnackbar } = useSnackbar();

  const getAllDashs = useCallback(
    async (searchValue, { skip = 0, take = 5 }) => {
      setLoading(true);
      const response = await callAPI(getAllDash, {
        clientId,
        searchValue,
        skip,
        take,
      });
      if (response) {
        setDashData(response);
      }
      setLoading(false);
    },
    [callAPI, clientId],
  );

  const createDash = useCallback(
    async bodyParams => {
      setLoading(true);
      const response = await callAPI(postCreateDash, bodyParams);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'A dash foi cadastrada com sucesso!',
        });
        getAllDashs(null, {});
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'A dash não foi cadastrada, favor verificar os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllDashs, showSnackbar],
  );

  const editDash = useCallback(
    async (bodyParams, dashId) => {
      setLoading(true);
      const response = await callAPI(patchEditDash, { bodyParams, dashId });

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'A dash foi editada com sucesso!',
        });
        getAllDashs(null, {});
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'A dash não foi editada, favor verificar os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllDashs, showSnackbar],
  );

  const getDashById = useCallback(
    async dashId => {
      const response = await callAPI(dashById, dashId);
      if (response) {
        setDefaultEditValue(response);
      }
    },
    [callAPI],
  );

  const removeDash = useCallback(
    async clientValueId => {
      setLoading(true);
      const response = await callAPI(deleteDash, clientValueId);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'A dash foi deletada com sucesso!',
        });
        getAllDashs(null, {});
        setLoading(false);
        return true;
      }

      showSnackbar({
        type: 'error',
        message: 'A dash não foi deletada, favor verificar os dados!',
      });
      setLoading(false);
      return false;
    },
    [callAPI, getAllDashs, showSnackbar],
  );

  const getCompany = useCallback(async () => {
    const response = await callAPI(getCompanyById, companyId);
    if (response) {
      setCompany(response);
    }
  }, [callAPI, companyId]);

  const getClient = useCallback(async () => {
    const response = await callAPI(getClientById, clientId);
    if (response) {
      setClient(response);
    }
  }, [callAPI, clientId]);

  useEffect(() => {
    getAllDashs(null, {});
  }, [getAllDashs]);

  useEffect(() => {
    getCompany();
    getClient();
  }, [getClient, getCompany]);

  return (
    <>
      <PageSkeleton details dash company={company} client={client}>
        <Tabs onChange={handleChangeTab} value={tabValue}>
          <Tab label="Dashboards vínculadas" index={0} />
        </Tabs>
        <TabPanel>
          <Table
            tableType="dash"
            data={dashData}
            clientId={clientId}
            create={createDash}
            edit={editDash}
            getAll={getAllDashs}
            getDashById={getDashById}
            defaultEditValue={defaultEditValue}
            remove={removeDash}
            loading={loading}
          />
        </TabPanel>
      </PageSkeleton>
    </>
  );
};

export default CompaniesDetailsDashs;
