import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { RiFileCopy2Fill as CopyIcon } from 'react-icons/ri';
import { Button } from '@material-ui/core';

import RecoveryPassword from '../../validations/ResendMail';
import ModalSkeleton from '../ModalSkeleton';
import TextInput from '../Inputs/Text/index';
import * as S from './styled';
import useAPI from '../../hooks/useApi';
import { patchEditUser } from '../../services/user';
import { useSnackbar } from '../../providers/snackbar';

const ResendMailModal = ({ opened, onCancel, buttonIcon, user, onSuccess }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RecoveryPassword),
  });
  const callApi = useAPI();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    reset({ email: user?.email });
    return () => reset();
  }, [reset, user?.email]);

  const onSend = useCallback(
    async data => {
      if (!user?.id) return;

      const response = await callApi(patchEditUser, {
        bodyParams: data,
        userId: user?.id,
        formData: false,
      });

      if (response) {
        onSuccess();
      } else {
        showSnackbar({
          type: 'error',
          message: 'Erro ao processar a solicitação. Tente novamente',
        });
      }
    },
    [callApi, onSuccess, showSnackbar, user?.id],
  );

  const onSubmit = data => {
    onSend(data);
    onCancel();
    reset();
  };

  const handleCopyLinkToClipboard = () => {
    navigator.clipboard.writeText(user.createPasswordUrl);

    showSnackbar({
      type: 'success',
      message: 'Link copiado!',
    });
  };

  return (
    <ModalSkeleton
      buttonIcon={buttonIcon}
      buttonText="Enviar"
      onSend={handleSubmit(onSubmit)}
      title="Reenviar"
      opened={opened}
      onCancel={onCancel}
    >
      <S.Wrapper>
        <S.Title>
          Revise o email cadastrado e informe o novo email caso esteja incorreto
        </S.Title>

        <TextInput
          control={control}
          errors={errors.email?.message}
          name="email"
          label="E-mail"
        />

        <Button
          variant="outlined"
          color="primary"
          startIcon={<CopyIcon />}
          onClick={handleCopyLinkToClipboard}
          style={{ marginTop: '1rem' }}
        >
          Copiar link do cadastro
        </Button>
      </S.Wrapper>
    </ModalSkeleton>
  );
};

ResendMailModal.defaultProps = {
  opened: false,
  onCancel: () => null,
  onSuccess: () => null,
  buttonIcon: null,
};

ResendMailModal.propTypes = {
  opened: PropTypes.bool,
  onCancel: PropTypes.func,
  buttonIcon: PropTypes.node,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    avatarUrl: PropTypes.string,
    active: PropTypes.bool,
    isBoweAdmin: PropTypes.bool,
    withoutPassword: PropTypes.bool,
    createPasswordUrl: PropTypes.string,
  }).isRequired,
  onSuccess: PropTypes.func,
};

export default ResendMailModal;
