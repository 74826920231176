import styled, { css } from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

import Wide from '../../assets/img/404Wide.svg';
import Web from '../../assets/img/404web.svg';
import IPad from '../../assets/img/404ipad.svg';
import Mobile from '../../assets/img/404mobile.svg';

const mediaQueryAndImgSrc = [
  { mediaSize: 1925, imgSrc: Web },
  { mediaSize: 1024, imgSrc: IPad },
  { mediaSize: 767, imgSrc: Mobile },
];

const returnMediaQuerys = mediaQueryAndImgSrc.map(
  item => css`
    @media (max-width: ${item.mediaSize}px) {
      background-image: url(${item.imgSrc});
    }
  `,
);

export const Container = styled.div`
  background-image: url(${Wide});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${returnMediaQuerys}
`;

export const Button = styled.button`
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 20px;
  width: 180px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: ${defaultTheme.colors.white};
  transition: all 0.7s ease;
  :hover {
    color: ${defaultTheme.colors.white};
    background-color: ${defaultTheme.colors.red};
    transition: all 0.7s ease;
    img {
      filter: invert();
      transition: all 0.7s ease;
    }
  }
  p {
    font-weight: 600;
  }
  img {
    display: flex;
    align-items: flex-start;
    width: 30px;
    margin: 0 5px;
  }
`;
