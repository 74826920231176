import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableCell, Box } from '@material-ui/core';
import * as S from './styled';

import ARROW from '../../../assets/icon/arrowIcon.svg';

const TableTitle = ({
  order,
  id,
  hasArrow,
  orderChange,
  onClick,
  children,
  ...props
}) => {
  const [arrow, setArrow] = useState(order);
  const changeArrow = () => {
    orderChange(id, !arrow);
    setArrow(!arrow);
  };

  useEffect(() => {
    setArrow(order);
  }, [order]);

  const runFunctions = () => {
    changeArrow();
    onClick();
  };

  return (
    <TableCell {...props}>
      <S.Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={runFunctions}
        arrow={hasArrow}
      >
        {children}
        <Box display="flex">
          {hasArrow && <S.Img src={ARROW} alt="Seta" active={arrow} />}
          <S.Divider />
        </Box>
      </S.Box>
    </TableCell>
  );
};

TableTitle.defaultProps = {
  order: true,
  hasArrow: true,
  onClick: () => null,
  orderChange: () => null,
  children: null,
  id: '',
};

TableTitle.propTypes = {
  id: PropTypes.string,
  orderChange: PropTypes.func,
  order: PropTypes.bool,
  hasArrow: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default TableTitle;
