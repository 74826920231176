import { API } from './api';

export const getPermission = async userId => {
  const api = await API({ auth: true, refresh: true });
  return api.get(`auth/permission/?userId=${userId}`);
};

export const postCreatePermission = async ({ userId, permission }) => {
  const api = await API({ auth: true, refresh: true });
  return api.post('/auth/permission', { userId, permission });
};

export const deleteRemovePermission = async permissionId => {
  const api = await API({ auth: true, refresh: true });
  return api.delete(`/auth/permission/${permissionId}`);
};
