import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiEditAlt as EditIcon } from 'react-icons/bi';
import {
  IoDocumentTextOutline as DocumentIcon,
  IoKeyOutline as KeyIcon,
} from 'react-icons/io5';
import { useTheme } from 'styled-components';
import WHITE_LOGO from '../../assets/img/weDashWhite.svg';
import Password from '../../components/Inputs/Password';
import useAPI from '../../hooks/useApi';
import { useSnackbar } from '../../providers/snackbar';
import { useUser } from '../../providers/user';
import { postGenerateApiToken } from '../../services/auth';
import { Avatar, Button, Head, PageSkeleton, Text } from '../../components';
import setObjectToFormData from '../../helpers/setObjectToFormData';
import { patchEditUser } from '../../services/user';
import EditUser from '../../validations/EditUser';
import * as S from './styled';

const Profile = () => {
  const [loadingEditUser, setLoadingEditUser] = useState(false);
  const [loadingApiToken, setLoadingApiToken] = useState(false);
  const { user, refreshUser } = useUser();
  const callAPI = useAPI();
  const { showSnackbar } = useSnackbar();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditUser),
  });
  const formData = useMemo(() => new FormData(), []);

  const onSubmit = useCallback(
    async data => {
      setLoadingEditUser(true);
      setObjectToFormData(formData, data);
      formData.delete('company');
      const params = {
        bodyParams: formData,
        userId: user.id,
      };
      const result = await callAPI(patchEditUser, params);
      if (!result) {
        showSnackbar({
          type: 'error',
          message: 'Erro ao editar usuário, favor verificar dados!',
        });
        setLoadingEditUser(false);
        return;
      }

      showSnackbar({
        type: 'success',
        message: 'Usuário editado com sucesso',
      });
      refreshUser();

      setLoadingEditUser(false);
    },
    [callAPI, formData, refreshUser, showSnackbar, user.id],
  );

  const generateApiToken = useCallback(async () => {
    setLoadingApiToken(true);
    const response = await callAPI(postGenerateApiToken);

    if (response) {
      refreshUser();
      showSnackbar({
        type: 'success',
        message: 'Token de API criado com sucesso!',
      });
    } else {
      showSnackbar({
        type: 'error',
        message: 'Não foi possível criar o token de api. Tente novamente',
        duration: 5000,
      });
    }

    setLoadingApiToken(false);
  }, [callAPI, refreshUser, showSnackbar]);

  const onChangeAvatar = file => {
    formData.append('avatar', file);
  };

  const { colors, customColor } = useTheme();

  useEffect(() => {
    reset({
      name: user.name,
      email: user.email,
      company: user.company?.name,
    });
  }, [reset, user, user.company?.name, user.email, user.name, user.auth]);

  return (
    <>
      <Head title="We.Dash | Profile" />
      <S.Wrapper>
        <PageSkeleton
          logoSrc={WHITE_LOGO}
          logoAlt="Escrita we.dash em cor clara"
          title="Meu perfil"
        >
          <S.Box mb={4} style={{ width: '100%', maxWidth: '128rem' }}>
            <S.Card>
              <S.CardHeader>
                <DocumentIcon
                  color={colors.primary}
                  size="4rem"
                  style={{
                    backgroundColor: customColor.violet.a10,
                    borderRadius: '50%',
                    padding: '0.9rem',
                    marginRight: '1rem',
                  }}
                />
                <Typography
                  variant="h5"
                  style={{ fontSize: '1.2rem', fontWeight: 500 }}
                >
                  Meus dados
                </Typography>
              </S.CardHeader>
              <S.FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Avatar
                    change
                    onChange={onChangeAvatar}
                    name={user.name}
                    avatarPath={user.avatarUrl}
                    size="106"
                    round=".8rem"
                  />
                  <S.InputsWrapper>
                    <Text
                      name="name"
                      errors={errors.name?.message}
                      control={control}
                      title="Nome"
                    />
                    <Text
                      name="email"
                      errors={errors.email?.message}
                      control={control}
                      title="E-mail"
                    />
                    <Text
                      name="company"
                      errors={errors.company?.message}
                      control={control}
                      title="Companhia"
                      disabled
                    />
                  </S.InputsWrapper>
                </div>
                <Button
                  text="Alterar dados"
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  type="submit"
                  loading={loadingEditUser}
                  style={{
                    minWidth: '16rem',
                    height: '4.2rem',
                    alignSelf: 'flex-end',
                  }}
                />
              </S.FormWrapper>
            </S.Card>
          </S.Box>
          <S.Box mb={4} style={{ width: '100%', maxWidth: '128rem' }}>
            <S.Card>
              <S.CardHeader>
                <KeyIcon
                  color={colors.primary}
                  size="4rem"
                  style={{
                    backgroundColor: customColor.violet.a10,
                    borderRadius: '50%',
                    padding: '0.9rem',
                    marginRight: '1rem',
                  }}
                />
                <Typography
                  variant="h5"
                  style={{ fontSize: '1.2rem', fontWeight: 500 }}
                >
                  Token de api
                </Typography>
              </S.CardHeader>
              <S.FormWrapper
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <Password
                  value={user.auth?.apiToken}
                  disabled
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ maxWidth: '50rem', marginRight: '1rem' }}
                />
                {!user.auth?.apiToken && (
                  <Button
                    loading={loadingApiToken}
                    variant="contained"
                    color="primary"
                    text="Gerar token de api"
                    onClick={generateApiToken}
                    style={{
                      minWidth: '16rem',
                      height: '4.9rem',
                      marginTop: 0,
                    }}
                  />
                )}
              </S.FormWrapper>
            </S.Card>
          </S.Box>
        </PageSkeleton>
      </S.Wrapper>
    </>
  );
};

export default Profile;
