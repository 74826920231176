/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  RiDeleteBin7Line as DeleteIcon,
  RiLinksLine as LinkIcon,
  RiPencilLine as PencilIcon,
} from 'react-icons/ri';
import truncateString from '../../../helpers/truncateString';
import Modal from '../../Modal';
import Tooltip from '../../Tooltip';
import * as S from '../styled';
import TableTitle from '../TableTitle';

const MAX_CELL_LENGTH = 50;

const DashTable = ({
  data,
  create,
  edit,
  remove,
  clientId,
  getDashById,
  defaultEditValue,
  getAll,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalState, setModalState] = useState(false);
  const [modalType, setModalType] = useState('create');
  const [dashIdState, setDashIdState] = useState(null);
  const [list, setList] = useState();
  const [orderState, setOrderState] = useState({
    dashName: false,
    url: false,
    actions: false,
  });
  const [inputValue, setInputValue] = useState('');

  const closeModal = () => setModalState(false);

  useEffect(() => {
    setList(data.result);
  }, [data]);

  useEffect(() => {
    getAll(inputValue, { skip: page, take: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAll, page, rowsPerPage]);

  const handleOrderChange = (id, value) => {
    if (value) {
      const stateCopy = { ...orderState };
      Object.keys(orderState).forEach(key => {
        stateCopy[key] = false;
      });
      stateCopy[id] = value;
      setOrderState(stateCopy);
    }
    orderUsersBy(id, value);
  };

  const orderUsersBy = (id, value) => {
    let dataCopy = [...data];
    dataCopy = dataCopy.sort((userA, userB) => {
      if (typeof userA[id] === 'string') {
        return value
          ? userB[id].localeCompare(userA[id])
          : userA[id].localeCompare(userB[id]);
      }
      return value ? userB[id] - userA[id] : userA[id] - userB[id];
    });
    setList(dataCopy);
  };

  const openModal = (type, dashId) => {
    if (modalState) {
      setModalState(false);
    }
    setModalType(type);
    setDashIdState(dashId);
    setModalState(true);
  };

  const returnModal = action => (
    <Modal
      type="dash"
      action={action}
      create={create}
      edit={edit}
      remove={remove}
      variableId={dashIdState}
      clientId={clientId}
      opened={modalState}
      onClose={() => setModalState(false)}
      getDashById={getDashById}
      defaultEditValue={defaultEditValue}
      closeOnSubmit={closeModal}
      loading={loading}
    />
  );

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 'dashName', label: 'Nome da DashBoard', width: '28%', arrow: true },
    { id: 'url', label: 'URL', width: '28%', arrow: true },
    { id: 'actions', label: 'Ações', width: '15%', arrow: false },
  ];

  function createDataTable(dashName, url, actions) {
    return { dashName, url, actions };
  }

  const buttonActions = dashId => (
    <S.Box display="flex">
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PencilIcon />}
        onClick={() => openModal('edit', dashId)}
      >
        Editar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={() => openModal('remove', dashId)}
      >
        Delete
      </Button>
    </S.Box>
  );

  const handleSearch = useCallback(
    e => {
      e.preventDefault();

      getAll(inputValue, { skip: page, take: rowsPerPage });
    },
    [getAll, inputValue, page, rowsPerPage],
  );

  const formatValue = (column, value) =>
    column.format && typeof value === 'number'
      ? column.format(value)
      : truncateString(value, MAX_CELL_LENGTH);

  const renderCell = (column, value) =>
    column.id === 'url' ? (
      <a href={value} target="_blank" rel="noreferrer">
        {formatValue(column, value)}
      </a>
    ) : (
      formatValue(column, value)
    );

  const fillTable = list
    ? list.map(element =>
        createDataTable(element.name, element.url, buttonActions(element.id)),
      )
    : [];

  return (
    <>
      {returnModal(modalType)}
      <S.Paper elevation={2}>
        <Box display="flex" flexDirection="column" p={2}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box width="25%">
              <Box bgcolor="rgba(245,245,245)" borderRadius="5px" px={1}>
                <form onSubmit={handleSearch}>
                  <Box display="flex" alignItems="center">
                    <InputBase
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      placeholder="Pesquisar"
                      fullWidth
                    />
                    <Divider orientation="vertical" />
                    <IconButton type="submit" aria-label="search">
                      <S.SearchIcon />
                    </IconButton>
                  </Box>
                </form>
              </Box>
            </Box>
            <S.TitleButton>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<LinkIcon />}
                onClick={() => openModal('create')}
              >
                Vincular nova dashboard
              </Button>
            </S.TitleButton>
          </Box>
          <Box py={3}>
            <Divider />
          </Box>
          <S.TableStyle>
            <S.TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map(columnTitle => (
                      <TableTitle
                        key={columnTitle.id}
                        id={columnTitle.id}
                        orderChange={handleOrderChange}
                        order={orderState[columnTitle.id]}
                        hasArrow={columnTitle.arrow}
                        style={{
                          width: columnTitle.width,
                        }}
                      >
                        {columnTitle.label}
                      </TableTitle>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <S.LoadingTable cols={3} />
                  ) : (
                    fillTable?.map(row => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map(column => {
                          const value = row[column.id];
                          const truncate = value.length > MAX_CELL_LENGTH;

                          return (
                            <S.TableCell key={column.id} align={column.align}>
                              {renderCell(column, value)}
                              {truncate && <Tooltip>{value}</Tooltip>}
                            </S.TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </S.TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
            />
          </S.TableStyle>
        </Box>
      </S.Paper>
    </>
  );
};

DashTable.defaultProps = {
  data: [],
  create: () => null,
  edit: () => null,
  remove: () => null,
  getDashById: () => null,
  clientId: '',
  defaultEditValue: null,
  getAll: () => null,
  loading: false,
};

DashTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      linkedDashboard: PropTypes.string,
      dashName: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  clientId: PropTypes.string,
  getDashById: PropTypes.func,
  getAll: PropTypes.func,
  defaultEditValue: PropTypes.node,
  loading: PropTypes.bool,
};

export default DashTable;
