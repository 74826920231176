import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddFill as PlusIcon,
  RiDeleteBin7Line as DeleteIcon,
  RiFolderLine as FolderIcon,
  RiPencilLine as PencilIcon,
} from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import Modal from '../../Modal';
import * as S from '../styled';
import TableTitle from '../TableTitle';

const CompanyTable = ({
  data,
  create,
  edit,
  remove,
  defaultEditValue,
  getCompanyById,
  getAll,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalState, setModalState] = useState(false);
  const [modalType, setModalType] = useState('create');
  const [companyIdState, setCompanyIdState] = useState(0);
  const [list, setList] = useState();
  const [inputValue, setInputValue] = useState('');

  const redirect = useHistory();
  const closeModal = () => setModalState(false);

  useEffect(() => {
    setList(data.result);
  }, [data]);

  useEffect(() => {
    getAll(inputValue, { skip: page, take: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAll, rowsPerPage, page, rowsPerPage]);

  const columns = [
    { id: 'companyName', label: 'Nome da Empresa', width: '27%', arrow: true },
    {
      id: 'numberOfCustomers',
      label: 'Quantidade de Clientes',
      width: '27%',
      arrow: true,
    },
    {
      id: 'numberOfUsers',
      label: 'Quantidade de Usuários',
      width: '27%',
      arrow: true,
    },
    { id: 'actions', label: 'Ações', width: '18%', arrow: false },
  ];

  const [orderState, setOrderState] = useState({
    companyName: false,
    numberOfCustomers: false,
    numberOfUsers: false,
    actions: false,
  });

  const handleOrderChange = (id, value) => {
    if (value) {
      const stateCopy = { ...orderState };
      Object.keys(orderState).forEach(key => {
        stateCopy[key] = false;
      });
      stateCopy[id] = value;
      setOrderState(stateCopy);
    }
    orderUsersBy(id, value);
  };

  const orderUsersBy = (id, value) => {
    let dataCopy = [...data];
    dataCopy = dataCopy.sort((userA, userB) => {
      if (typeof userA[id] === 'string') {
        return value
          ? userB[id].localeCompare(userA[id])
          : userA[id].localeCompare(userB[id]);
      }
      return value ? userB[id] - userA[id] : userA[id] - userB[id];
    });
    setList(dataCopy);
  };

  function createDataTable(
    companyName,
    numberOfCustomers,
    numberOfUsers,
    actions,
  ) {
    return { companyName, numberOfCustomers, numberOfUsers, actions };
  }

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const returnModal = action => (
    <Modal
      type="company"
      action={action}
      create={create}
      edit={edit}
      getCompanyById={getCompanyById}
      defaultEditValue={defaultEditValue}
      remove={remove}
      variableId={companyIdState}
      opened={modalState}
      onClose={closeModal}
      closeOnSubmit={closeModal}
      loading={loading}
    />
  );

  const openModal = (type, companyId) => {
    if (!modalState) {
      setModalType(type);
      setCompanyIdState(companyId);
      setModalState(true);
      return;
    }
    setModalState(false);
  };

  const buttonActions = companyId => (
    <S.Box display="flex" three>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        onClick={() => redirect.push(`/companies/details/${companyId}`)}
        startIcon={<FolderIcon />}
      >
        Visualizar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<PencilIcon />}
        onClick={() => openModal('edit', companyId)}
      >
        Editar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={() => openModal('remove', companyId)}
      >
        Excluir
      </Button>
    </S.Box>
  );

  const handleSearch = useCallback(
    e => {
      e.preventDefault();

      getAll(inputValue, { skip: page, take: rowsPerPage });
    },
    [getAll, inputValue, page, rowsPerPage],
  );

  const fillTable = list
    ? list.map(element =>
        createDataTable(
          element.name,
          element.clients,
          element.users,
          buttonActions(element.id),
        ),
      )
    : [];

  return (
    <>
      {returnModal(modalType)}
      <S.Paper elevation={2}>
        <Box display="flex" flexDirection="column" p={2}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box width="25%">
              <Box bgcolor="rgba(245,245,245)" borderRadius="5px" px={1}>
                <form onSubmit={handleSearch}>
                  <Box display="flex" alignItems="center">
                    <InputBase
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      placeholder="Pesquisar"
                      fullWidth
                    />
                    <Divider orientation="vertical" />
                    <IconButton type="submit" aria-label="search">
                      <S.SearchIcon />
                    </IconButton>
                  </Box>
                </form>
              </Box>
            </Box>
            <S.TitleButton>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PlusIcon />}
                onClick={() => openModal('create')}
              >
                Cadastrar nova companhia
              </Button>
            </S.TitleButton>
          </Box>
          <Box py={3}>
            <Divider />
          </Box>

          <S.TableStyle>
            <S.TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map(columnTitle => (
                      <TableTitle
                        key={columnTitle.id}
                        id={columnTitle.id}
                        orderChange={handleOrderChange}
                        order={orderState[columnTitle.id]}
                        hasArrow={columnTitle.arrow}
                        style={{
                          width: columnTitle.width,
                        }}
                      >
                        {columnTitle.label}
                      </TableTitle>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <S.LoadingTable cols={4} />
                  ) : (
                    fillTable?.map(row => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </S.TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
            />
          </S.TableStyle>
        </Box>
      </S.Paper>
    </>
  );
};

CompanyTable.defaultProps = {
  data: [],
  create: () => null,
  edit: () => null,
  remove: () => null,
  defaultEditValue: '',
  getCompanyById: () => null,
  getAll: () => null,
  loading: false,
};

CompanyTable.propTypes = {
  data: PropTypes.node,
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  defaultEditValue: PropTypes.string,
  getCompanyById: PropTypes.func,
  getAll: PropTypes.func,
  loading: PropTypes.bool,
};

export default CompanyTable;
