import { Button, FormControl, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiDeleteBin7Line as DeleteIcon } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import useAPI from '../../../../hooks/useApi';
import { getAllClients } from '../../../../services/clients';
import ModalSkeleton from '../../../ModalSkeleton';
import * as S from '../../styled';
import DeleteModal from './DeleteModal';

const PermissionsUserModal = ({
  opened,
  onClose,
  getDashsByClient,
  createPermission,
  variableId,
  permissionsData,
  getAllPermissions,
  removePermission,
  loading,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedDash, setSelectedDash] = useState('');
  const [selectVariableId, setSelectVariableId] = useState('');
  const [dashs, setDashs] = useState([]);
  const [clients, setClients] = useState([]);
  const callAPI = useAPI();
  const { companyId } = useParams();

  const openDeleteModal = () => setDeleteModal(true);
  const closeDeleteModal = () => setDeleteModal(false);

  const fetchClients = useCallback(async () => {
    const response = await callAPI(getAllClients, { companyId });
    setClients(response);
  }, [callAPI, companyId]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleChangeClient = useCallback(
    async event => {
      const { value: clientId } = event.target;
      setSelectedClient(clientId);
      const response = await getDashsByClient(clientId);
      if (response) {
        setDashs(response);
      }
    },
    [getDashsByClient],
  );

  const deleteButtonClick = useCallback(id => {
    setSelectVariableId(id);
    openDeleteModal();
  }, []);

  const PermissionsCard = () => (
    <>
      {permissionsData?.map(element => (
        <S.Wrapper>
          <div>
            <S.Title variant="h6" subtitle>
              {element.clientName}
            </S.Title>
          </div>
          <div>
            <div>
              <S.Title variant="h6" light>
                {element.entityName}
              </S.Title>
            </div>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => deleteButtonClick(element.id)}
            >
              Delete
            </Button>
          </div>
        </S.Wrapper>
      ))}
    </>
  );

  const hasPermissions = permissionsData.length !== 0;

  const permissionPropsRender = {
    true: {
      secondTitle: 'Permissões dadas',
      secondChildren: PermissionsCard(),
    },
  };

  const { handleSubmit } = useForm();

  const refreshPermissions = useCallback(() => {
    getAllPermissions(variableId);
  }, [getAllPermissions, variableId]);

  const onSubmit = useCallback(async () => {
    const params = {
      userId: variableId,
      permission: `${selectedClient}:${selectedDash}:GET`,
    };
    await createPermission(params);
    refreshPermissions();
  }, [
    createPermission,
    refreshPermissions,
    selectedClient,
    selectedDash,
    variableId,
  ]);

  useEffect(() => {
    getAllPermissions(variableId);
  }, [getAllPermissions, variableId, removePermission]);

  return (
    <>
      <ModalSkeleton
        title="Gerenciar permissões"
        opened={opened}
        onCancel={onClose}
        buttonText="Adicionar permissão"
        onSend={handleSubmit(onSubmit)}
        {...permissionPropsRender[hasPermissions]}
        loading={loading}
        minSizeBig
      >
        <S.ModalWrapper>
          <FormControl variant="outlined">
            <InputLabel>Cliente</InputLabel>
            <S.Select
              labelId="demo-simple-select-outlined-label"
              label="Cliente"
              onChange={handleChangeClient}
            >
              <S.MenuItem value={0}>
                <em>Selecione</em>
              </S.MenuItem>
              {clients?.result?.map(menuItem => (
                <S.MenuItem value={menuItem.id} key={menuItem.id}>
                  <em>{menuItem.name}</em>
                </S.MenuItem>
              ))}
            </S.Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>Dashboard</InputLabel>
            <S.Select
              labelId="demo-simple-select-outlined-label"
              label="Dashboard"
              onChange={event => setSelectedDash(event.target.value)}
            >
              <S.MenuItem value={0}>
                <em>Selecione</em>
              </S.MenuItem>
              {dashs?.result?.map(menuItem => (
                <S.MenuItem value={menuItem.id} key={menuItem.id}>
                  <em>{menuItem.name}</em>
                </S.MenuItem>
              ))}
            </S.Select>
          </FormControl>
        </S.ModalWrapper>
      </ModalSkeleton>
      <DeleteModal
        opened={deleteModal}
        onClose={closeDeleteModal}
        variableId={selectVariableId}
        refreshPermissions={refreshPermissions}
        remove={removePermission}
        loading={loading}
      />
    </>
  );
};

PermissionsUserModal.defaultProps = {
  opened: false,
  onClose: () => null,
  getDashsByClient: () => null,
  createPermission: () => null,
  getAllPermissions: () => null,
  removePermission: () => null,
  clients: [],
  permissionsData: [],
  variableId: '',
  loading: false,
};

PermissionsUserModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  createPermission: PropTypes.func,
  getDashsByClient: PropTypes.func,
  getAllPermissions: PropTypes.func,
  removePermission: PropTypes.func,
  clients: PropTypes.any,
  permissionsData: PropTypes.any,
  variableId: PropTypes.string,
  loading: PropTypes.bool,
};

export default PermissionsUserModal;
