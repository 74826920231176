import styled, { css } from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

const spinnerType = {
  button: css`
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid;
  `,
};

export const Spinner = styled.div`
  border-radius: 50%;
  ${({ type }) => spinnerType[type]};
  border-color: ${({ shapeColor }) => `${defaultTheme.colors[shapeColor]}1A`};
  border-left-color: ${({ loadColor }) => defaultTheme.colors[loadColor]};
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
