import decodeJWT from 'jwt-decode';
import { API } from './api';

const [ACCESS_TOKEN, REFRESH_TOKEN] = ['ACCESS_TOKEN', 'REFRESH_TOKEN'];

export const signIn = async credentials => {
  const api = await API({ refresh: false });

  const result = await api.post('/auth/login', credentials);
  const { data } = result;
  localStorage.setItem(ACCESS_TOKEN, data.access_token);
  localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
  return result;
};

export const getUserInfo = async () => {
  const api = await API({ auth: true });

  const result = await api.get('/user');
  const { data } = result;

  return data;
};

export const isLoggedIn = getUserInfo;

export const refreshToken = async () => {
  const api = await API({ auth: false, refresh: false });

  const oldAccessToken = localStorage.getItem(ACCESS_TOKEN);
  const oldRefreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (oldRefreshToken && oldAccessToken) {
    const { data } = await api.post('/auth/refresh', {
      access_token: oldAccessToken,
      refresh_token: oldRefreshToken,
    });

    localStorage.setItem(ACCESS_TOKEN, data.access_token);
    localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
  }
};

export const logout = async () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const getCurrentuser = async () => {
  const api = await API({ auth: true, refresh: true });
  await api.get('/user/current');
};

export const checkAuth = async () => {
  try {
    const localAccessToken = localStorage.getItem(ACCESS_TOKEN);
    const localRefreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (localAccessToken && localRefreshToken) {
      await getCurrentuser();
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserId = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) return null;
  const { id } = decodeJWT(token);
  return id;
};

export const postGenerateApiToken = async () => {
  const api = await API({ auth: true, refresh: true });

  const response = await api.post('/auth/api-token');

  return response.data;
};

export const getCompanyId = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) return null;

  const { companyId } = decodeJWT(token);
  return companyId;
};
