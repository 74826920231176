import { Tabs as MUITabs } from '@material-ui/core';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/defaultTheme';

export const Tabs = styled(MUITabs)`
  background-color: ${defaultTheme.colors.primary};
  border: 0.1rem solid transparent;
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  .Mui-selected {
    color: ${defaultTheme.colors.white};
  }
  .MuiTabs-indicator {
    background-color: ${defaultTheme.colors.white};
  }
`;
