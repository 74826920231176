import styled, { css } from 'styled-components';
import { Box as MaterialBox } from '@material-ui/core';
import { defaultTheme } from '../../styles/defaultTheme';

export const Content = styled.div`
  height: max-content;
  height: 100%;
`;
export const Bar = styled.div`
  min-width: 25rem;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 100%;
  min-height: 100%;
  box-shadow: 0.3rem 0.6rem 1rem #00000029;
`;

const hoverOrActiveItem = css`
  h6 {
    font-size: 1.7rem;
    color: ${defaultTheme.typography.primary};
    opacity: 0.7s;
    transition: all 0.7s;
  }
  img {
    filter: invert(46%) sepia(87%) saturate(7481%) hue-rotate(240deg)
      brightness(89%) contrast(90%);
    margin-right: 2rem;
    width: 2rem;
    transition: all 0.7s;
  }
`;

export const MenuWrapper = styled.div`
  margin: 2.5rem 0;

  > div + div {
    padding-top: 2.5rem;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  ${({ $end }) =>
    $end
      ? css`
          padding: 2.5rem;
        `
      : css`
          padding-right: 2.5rem;
          padding-left: 2.5rem;
        `}

  ${({ isActive }) =>
    isActive
      ? hoverOrActiveItem
      : css`
          h6 {
            font-size: 1.7rem;
            color: ${defaultTheme.typography.gray};
            transition: all 0.7s;
          }
          img {
            filter: invert(69%) sepia(42%) saturate(0%) hue-rotate(150deg)
              brightness(99%) contrast(90%);
            margin-right: 2rem;
            width: 2rem;
            transition: all 0.7s;
          }
        `}
  :hover {
    ${hoverOrActiveItem}
    transition: all 0.7s;
  }
`;
