import PropTypes from 'prop-types';
import CreateUserModal from './CreateUserModal';
import EditUserModal from './EditUserModal';
import DeleteUserModal from './DeleteUserModal';
import PermissionsUserModal from './PermissionsUserModal';

const ClientModals = ({
  action,
  create,
  edit,
  remove,
  permissions,
  ...props
}) => {
  const actionMap = {
    create: <CreateUserModal create={create} {...props} />,
    edit: <EditUserModal edit={edit} {...props} />,
    remove: <DeleteUserModal remove={remove} {...props} />,
    permissions: <PermissionsUserModal permissions={permissions} {...props} />,
  };

  return actionMap[action];
};

ClientModals.defaultProps = {
  action: 'create',
  create: () => null,
  edit: () => null,
  remove: () => null,
  permissions: () => null,
};

ClientModals.propTypes = {
  action: PropTypes.oneOf(['create', 'edit', 'remove', 'permissions']),
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  permissions: PropTypes.func,
};

export default ClientModals;
